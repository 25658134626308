/**
 * Encore Foundation - 5.32.1
 *
 * The contents of this file is owned and generated by the Encore team.
 * If you need to make changes to the values stored here please reach out
 * to #encore on Slack.
 *
 * Links:
 *
 * - Source & Documentation: https://ghe.spotify.net/encore/foundation
 *
 * Tack!
 *
 * Encore
 * encore.spotify.net
 */

/* The Ad Analytics Light theme is used for the Ad Analytics product, a product to measure the performance of advertising campaigns. */
/* Lifecycle status: experimental */
.encore-ad-analytics-light-theme,
.encore-ad-analytics-light-theme .encore-base-set {
  /* The default background color of the base set. */
  --background-base: #ffffff;
  /* A background color that indicates the hover state of a component, or adds some oomph to a focus state. */
  --background-highlight: #f6f6f6;
  /* A background color that indicates the press state of a component. */
  --background-press: #b7b7b7;
  /* The default background color for elevated elements in the base set. */
  --background-elevated-base: #ffffff;
  --background-elevated-highlight: #f6f6f6;
  --background-elevated-press: #b7b7b7;
  /* The default background color for tinted elements in the base set. */
  --background-tinted-base: rgba(0, 0, 0, 0.08);
  --background-tinted-highlight: rgba(0, 0, 0, 0.05);
  --background-tinted-press: rgba(0, 0, 0, 0.12);
  /* The default text color of the base set. */
  --text-base: #000000;
  /* A subdued variant of the base text color. */
  --text-subdued: #6a6a6a;
  /* A bright text color that contrasts with the set’s background colors. Often used to give key interactive elements more prominence. */
  --text-bright-accent: #567408;
  /* A text color associated with negativity. Often used for errors, destructive actions, or to highlight negative metrics or data. */
  --text-negative: #d31225;
  /* A text color associated with caution. Often used to highlight potential problems or slightly negative metrics or data. */
  --text-warning: #9d5a00;
  /* A text color associated with positivity. Often used for success states, or to highlight positive metrics or data. */
  --text-positive: #117a37;
  /* A text color associated with system announcements and notifications. */
  --text-announcement: #0c67d3;
  /* The default essential color of the base set. */
  --essential-base: #000000;
  /* A subdued variant of the base essential color. */
  --essential-subdued: #878787;
  /* A bright essential color that contrasts with the set’s background colors. Often used to give key interactive elements more prominence. */
  --essential-bright-accent: #6d930a;
  /* An essential color associated with negativity. Often used for errors, destructive actions, or to highlight negative metrics or data. */
  --essential-negative: #e91429;
  /* An essential color associated with caution. Often used to highlight potential problems or slightly negative metrics or data */
  --essential-warning: #c77200;
  /* An essential color associated with positivity. Often used for success states, or to highlight positive metrics or data. */
  --essential-positive: #169b45;
  /* An essential color associated with system announcements and notifications. */
  --essential-announcement: #0d72ea;
  /* The default decorative color of the base set. */
  --decorative-base: #000000;
  /* A subdued variant of the base decorative color. */
  --decorative-subdued: #dedede;
}
.encore-ad-analytics-light-theme > *,
.encore-ad-analytics-light-theme .encore-base-set > * {
  --parents-essential-base: #000000;
}
.encore-ad-analytics-light-theme .encore-bright-accent-set {
  /* The default background color of the brightAccent set. */
  --background-base: #cff56a;
  /* A background color that indicates the hover state of a component, or adds some oomph to a focus state. */
  --background-highlight: #e2f9a6;
  /* A background color that indicates the press state of a component. */
  --background-press: #8bba0c;
  /* Fallback color */
  --background-elevated-base: #e2f9a6;
  /* Fallback color */
  --background-elevated-highlight: #e2f9a6;
  /* Fallback color */
  --background-elevated-press: #8bba0c;
  /* Fallback color */
  --background-tinted-base: #cff56a;
  /* Fallback color */
  --background-tinted-highlight: #cff56a;
  /* Fallback color */
  --background-tinted-press: #cff56a;
  /* The default text color of the brightAccent set. */
  --text-base: #000000;
  /* Fallback color */
  --text-subdued: #000000;
  /* Fallback color */
  --text-bright-accent: #000000;
  /* Fallback color */
  --text-negative: #000000;
  /* Fallback color */
  --text-warning: #000000;
  /* Fallback color */
  --text-positive: #000000;
  /* Fallback color */
  --text-announcement: #000000;
  /* The default essential color of the brightAccent set. */
  --essential-base: #000000;
  /* Fallback color */
  --essential-subdued: #000000;
  /* Fallback color */
  --essential-bright-accent: #000000;
  /* Fallback color */
  --essential-negative: #000000;
  /* Fallback color */
  --essential-warning: #000000;
  /* Fallback color */
  --essential-positive: #000000;
  /* Fallback color */
  --essential-announcement: #000000;
  /* The default decorative color of the brightAccent set. */
  --decorative-base: #000000;
  /* A subdued variant of the base decorative color. */
  --decorative-subdued: #a8e20f;
}
.encore-ad-analytics-light-theme .encore-bright-accent-set > * {
  --parents-essential-base: #000000;
}
.encore-ad-analytics-light-theme .encore-negative-set {
  /* The default background color of the negative set. */
  --background-base: #e91429;
  /* A background color that indicates the hover state of a component, or adds some oomph to a focus state. */
  --background-highlight: #de1327;
  /* A background color that indicates the press state of a component. */
  --background-press: #920d1a;
  /* Fallback color */
  --background-elevated-base: #de1327;
  /* Fallback color */
  --background-elevated-highlight: #de1327;
  /* Fallback color */
  --background-elevated-press: #920d1a;
  /* Fallback color */
  --background-tinted-base: #e91429;
  /* Fallback color */
  --background-tinted-highlight: #e91429;
  /* Fallback color */
  --background-tinted-press: #e91429;
  /* The default text color of the negative set. */
  --text-base: #ffffff;
  /* Fallback color */
  --text-subdued: #ffffff;
  /* Fallback color */
  --text-bright-accent: #ffffff;
  /* Fallback color */
  --text-negative: #ffffff;
  /* Fallback color */
  --text-warning: #ffffff;
  /* Fallback color */
  --text-positive: #ffffff;
  /* Fallback color */
  --text-announcement: #ffffff;
  /* The default essential color of the negative set. */
  --essential-base: #ffffff;
  /* Fallback color */
  --essential-subdued: #ffffff;
  /* Fallback color */
  --essential-bright-accent: #ffffff;
  /* Fallback color */
  --essential-negative: #ffffff;
  /* Fallback color */
  --essential-warning: #ffffff;
  /* Fallback color */
  --essential-positive: #ffffff;
  /* Fallback color */
  --essential-announcement: #ffffff;
  /* The default decorative color of the negative set. */
  --decorative-base: #ffffff;
  /* A subdued variant of the base decorative color. */
  --decorative-subdued: #ee3a4c;
}
.encore-ad-analytics-light-theme .encore-negative-set > * {
  --parents-essential-base: #ffffff;
}
.encore-ad-analytics-light-theme .encore-warning-set {
  /* The default background color of the warning set. */
  --background-base: #ffa42b;
  /* A background color that indicates the hover state of a component, or adds some oomph to a focus state. */
  --background-highlight: #ffaf45;
  /* A background color that indicates the press state of a component. */
  --background-press: #c57100;
  /* Fallback color */
  --background-elevated-base: #ffaf45;
  /* Fallback color */
  --background-elevated-highlight: #ffaf45;
  /* Fallback color */
  --background-elevated-press: #c57100;
  /* Fallback color */
  --background-tinted-base: #ffa42b;
  /* Fallback color */
  --background-tinted-highlight: #ffa42b;
  /* Fallback color */
  --background-tinted-press: #ffa42b;
  /* The default text color of the warning set. */
  --text-base: #000000;
  /* Fallback color */
  --text-subdued: #000000;
  /* Fallback color */
  --text-bright-accent: #000000;
  /* Fallback color */
  --text-negative: #000000;
  /* Fallback color */
  --text-warning: #000000;
  /* Fallback color */
  --text-positive: #000000;
  /* Fallback color */
  --text-announcement: #000000;
  /* The default essential color of the warning set. */
  --essential-base: #000000;
  /* Fallback color */
  --essential-subdued: #000000;
  /* Fallback color */
  --essential-bright-accent: #000000;
  /* Fallback color */
  --essential-negative: #000000;
  /* Fallback color */
  --essential-warning: #000000;
  /* Fallback color */
  --essential-positive: #000000;
  /* Fallback color */
  --essential-announcement: #000000;
  /* The default decorative color of the warning set. */
  --decorative-base: #000000;
  /* A subdued variant of the base decorative color. */
  --decorative-subdued: #f18900;
}
.encore-ad-analytics-light-theme .encore-warning-set > * {
  --parents-essential-base: #000000;
}
.encore-ad-analytics-light-theme .encore-positive-set {
  /* The default background color of the positive set. */
  --background-base: #1ed760;
  /* A background color that indicates the hover state of a component, or adds some oomph to a focus state. */
  --background-highlight: #1fdf64;
  /* A background color that indicates the press state of a component. */
  --background-press: #169c46;
  /* Fallback color */
  --background-elevated-base: #1fdf64;
  /* Fallback color */
  --background-elevated-highlight: #1fdf64;
  /* Fallback color */
  --background-elevated-press: #169c46;
  /* Fallback color */
  --background-tinted-base: #1ed760;
  /* Fallback color */
  --background-tinted-highlight: #1ed760;
  /* Fallback color */
  --background-tinted-press: #1ed760;
  /* The default text color of the positive set. */
  --text-base: #000000;
  /* Fallback color */
  --text-subdued: #000000;
  /* Fallback color */
  --text-bright-accent: #000000;
  /* Fallback color */
  --text-negative: #000000;
  /* Fallback color */
  --text-warning: #000000;
  /* Fallback color */
  --text-positive: #000000;
  /* Fallback color */
  --text-announcement: #000000;
  /* The default essential color of the positive set. */
  --essential-base: #000000;
  /* Fallback color */
  --essential-subdued: #000000;
  /* Fallback color */
  --essential-bright-accent: #000000;
  /* Fallback color */
  --essential-negative: #000000;
  /* Fallback color */
  --essential-warning: #000000;
  /* Fallback color */
  --essential-positive: #000000;
  /* Fallback color */
  --essential-announcement: #000000;
  /* The default decorative color of the positive set. */
  --decorative-base: #000000;
  /* A subdued variant of the base decorative color. */
  --decorative-subdued: #1abc54;
}
.encore-ad-analytics-light-theme .encore-positive-set > * {
  --parents-essential-base: #000000;
}
.encore-ad-analytics-light-theme .encore-announcement-set {
  /* The default background color of the announcement set. */
  --background-base: #0d72ea;
  /* A background color that indicates the hover state of a component, or adds some oomph to a focus state. */
  --background-highlight: #0c6ddf;
  /* A background color that indicates the press state of a component. */
  --background-press: #084791;
  /* Fallback color */
  --background-elevated-base: #0c6ddf;
  /* Fallback color */
  --background-elevated-highlight: #0c6ddf;
  /* Fallback color */
  --background-elevated-press: #084791;
  /* Fallback color */
  --background-tinted-base: #0d72ea;
  /* Fallback color */
  --background-tinted-highlight: #0d72ea;
  /* Fallback color */
  --background-tinted-press: #0d72ea;
  /* The default text color of the announcement set. */
  --text-base: #ffffff;
  /* Fallback color */
  --text-subdued: #ffffff;
  /* Fallback color */
  --text-bright-accent: #ffffff;
  /* Fallback color */
  --text-negative: #ffffff;
  /* Fallback color */
  --text-warning: #ffffff;
  /* Fallback color */
  --text-positive: #ffffff;
  /* Fallback color */
  --text-announcement: #ffffff;
  /* The default essential color of the announcement set. */
  --essential-base: #ffffff;
  /* Fallback color */
  --essential-subdued: #ffffff;
  /* Fallback color */
  --essential-bright-accent: #ffffff;
  /* Fallback color */
  --essential-negative: #ffffff;
  /* Fallback color */
  --essential-warning: #ffffff;
  /* Fallback color */
  --essential-positive: #ffffff;
  /* Fallback color */
  --essential-announcement: #ffffff;
  /* The default decorative color of the announcement set. */
  --decorative-base: #ffffff;
  /* A subdued variant of the base decorative color. */
  --decorative-subdued: #2a86f3;
}
.encore-ad-analytics-light-theme .encore-announcement-set > * {
  --parents-essential-base: #ffffff;
}
.encore-ad-analytics-light-theme .encore-inverted-dark-set {
  /* The default background color of the invertedDark set. */
  --background-base: #000000;
  /* A background color that indicates the hover state of a component, or adds some oomph to a focus state. */
  --background-highlight: #0d0d0d;
  /* A background color that indicates the press state of a component. */
  --background-press: #3f3f3f;
  /* Fallback color */
  --background-elevated-base: #0d0d0d;
  /* Fallback color */
  --background-elevated-highlight: #0d0d0d;
  /* Fallback color */
  --background-elevated-press: #3f3f3f;
  /* Fallback color */
  --background-tinted-base: #000000;
  /* Fallback color */
  --background-tinted-highlight: #000000;
  /* Fallback color */
  --background-tinted-press: #000000;
  /* The default text color of the invertedDark set. */
  --text-base: #ffffff;
  /* A subdued variant of the base text color. */
  --text-subdued: #8f8f8f;
  /* A bright text color that contrasts with the set’s background colors. Often used to give key interactive elements more prominence. */
  --text-bright-accent: #cff56a;
  /* Fallback color */
  --text-negative: #ffffff;
  /* Fallback color */
  --text-warning: #ffffff;
  /* Fallback color */
  --text-positive: #ffffff;
  /* Fallback color */
  --text-announcement: #ffffff;
  /* The default essential color of the invertedDark set. */
  --essential-base: #ffffff;
  /* A subdued variant of the base essential color. */
  --essential-subdued: #5e5e5e;
  /* A bright essential color that contrasts with the set’s background colors. Often used to give key interactive elements more prominence. */
  --essential-bright-accent: #cff56a;
  /* Fallback color */
  --essential-negative: #ffffff;
  /* Fallback color */
  --essential-warning: #ffffff;
  /* Fallback color */
  --essential-positive: #ffffff;
  /* Fallback color */
  --essential-announcement: #ffffff;
  /* The default decorative color of the invertedDark set. */
  --decorative-base: #ffffff;
  /* A subdued variant of the base decorative color. */
  --decorative-subdued: #1f1f1f;
}
.encore-ad-analytics-light-theme .encore-inverted-dark-set > * {
  --parents-essential-base: #ffffff;
}
.encore-ad-analytics-light-theme .encore-inverted-light-set {
  /* The default background color of the invertedLight set. */
  --background-base: #ffffff;
  /* A background color that indicates the hover state of a component, or adds some oomph to a focus state. */
  --background-highlight: #f6f6f6;
  /* A background color that indicates the press state of a component. */
  --background-press: #b7b7b7;
  /* Fallback color */
  --background-elevated-base: #f6f6f6;
  /* Fallback color */
  --background-elevated-highlight: #f6f6f6;
  /* Fallback color */
  --background-elevated-press: #b7b7b7;
  /* Fallback color */
  --background-tinted-base: #ffffff;
  /* Fallback color */
  --background-tinted-highlight: #ffffff;
  /* Fallback color */
  --background-tinted-press: #ffffff;
  /* The default text color of the invertedLight set. */
  --text-base: #000000;
  /* A subdued variant of the base text color. */
  --text-subdued: #5e5e5e;
  /* A bright text color that contrasts with the set’s background colors. Often used to give key interactive elements more prominence. */
  --text-bright-accent: #5c7c08;
  /* Fallback color */
  --text-negative: #000000;
  /* Fallback color */
  --text-warning: #000000;
  /* Fallback color */
  --text-positive: #000000;
  /* Fallback color */
  --text-announcement: #000000;
  /* The default essential color of the invertedLight set. */
  --essential-base: #000000;
  /* A subdued variant of the base essential color. */
  --essential-subdued: #8f8f8f;
  /* A bright essential color that contrasts with the set’s background colors. Often used to give key interactive elements more prominence. */
  --essential-bright-accent: #749c0a;
  /* Fallback color */
  --essential-negative: #000000;
  /* Fallback color */
  --essential-warning: #000000;
  /* Fallback color */
  --essential-positive: #000000;
  /* Fallback color */
  --essential-announcement: #000000;
  /* The default decorative color of the invertedLight set. */
  --decorative-base: #000000;
  /* A subdued variant of the base decorative color. */
  --decorative-subdued: #dedede;
}
.encore-ad-analytics-light-theme .encore-inverted-light-set > * {
  --parents-essential-base: #000000;
}
.encore-ad-analytics-light-theme .encore-muted-accent-set {
  /* The default background color of the mutedAccent set. */
  --background-base: #121212;
  /* A background color that indicates the hover state of a component, or adds some oomph to a focus state. */
  --background-highlight: #1a1a1a;
  /* A background color that indicates the press state of a component. */
  --background-press: #000000;
  /* The default background color for tinted elements in the mutedAccent set. */
  --background-tinted-base: rgba(255, 255, 255, 0.07);
  --background-tinted-highlight: rgba(255, 255, 255, 0.1);
  --background-tinted-press: rgba(255, 255, 255, 0.04);
  /* Fallback color */
  --background-elevated-base: #1a1a1a;
  /* Fallback color */
  --background-elevated-highlight: #1a1a1a;
  /* Fallback color */
  --background-elevated-press: #000000;
  /* The default text color of the mutedAccent set. */
  --text-base: #ffffff;
  /* A subdued variant of the base text color. */
  --text-subdued: #a7a7a7;
  /* A bright text color that contrasts with the set’s background colors. Often used to give key interactive elements more prominence. */
  --text-bright-accent: #cff56a;
  /* Fallback color */
  --text-negative: #ffffff;
  /* Fallback color */
  --text-warning: #ffffff;
  /* Fallback color */
  --text-positive: #ffffff;
  /* Fallback color */
  --text-announcement: #ffffff;
  /* The default essential color of the mutedAccent set. */
  --essential-base: #ffffff;
  /* A subdued variant of the base essential color. */
  --essential-subdued: #727272;
  /* A bright essential color that contrasts with the set’s background colors. Often used to give key interactive elements more prominence. */
  --essential-bright-accent: #cff56a;
  /* Fallback color */
  --essential-negative: #ffffff;
  /* Fallback color */
  --essential-warning: #ffffff;
  /* Fallback color */
  --essential-positive: #ffffff;
  /* Fallback color */
  --essential-announcement: #ffffff;
  /* The default decorative color of the mutedAccent set. */
  --decorative-base: #ffffff;
  /* A subdued variant of the base decorative color. */
  --decorative-subdued: #292929;
}
.encore-ad-analytics-light-theme .encore-muted-accent-set > * {
  --parents-essential-base: #ffffff;
}
.encore-ad-analytics-light-theme .encore-over-media-set {
  /* The default background color of the overMedia set. */
  --background-base: rgba(0, 0, 0, 0.54);
  /* A background color that indicates the hover state of a component, or adds some oomph to a focus state. */
  --background-highlight: rgba(0, 0, 0, 0.56);
  /* A background color that indicates the press state of a component. */
  --background-press: rgba(0, 0, 0, 0.72);
  /* Fallback color */
  --background-elevated-base: rgba(0, 0, 0, 0.56);
  /* Fallback color */
  --background-elevated-highlight: rgba(0, 0, 0, 0.56);
  /* Fallback color */
  --background-elevated-press: rgba(0, 0, 0, 0.72);
  /* Fallback color */
  --background-tinted-base: rgba(0, 0, 0, 0.54);
  /* Fallback color */
  --background-tinted-highlight: rgba(0, 0, 0, 0.54);
  /* Fallback color */
  --background-tinted-press: rgba(0, 0, 0, 0.54);
  /* The default text color of the overMedia set. */
  --text-base: #ffffff;
  /* Fallback color */
  --text-subdued: #ffffff;
  /* Fallback color */
  --text-bright-accent: #ffffff;
  /* Fallback color */
  --text-negative: #ffffff;
  /* Fallback color */
  --text-warning: #ffffff;
  /* Fallback color */
  --text-positive: #ffffff;
  /* Fallback color */
  --text-announcement: #ffffff;
  /* The default essential color of the overMedia set. */
  --essential-base: #ffffff;
  /* Fallback color */
  --essential-subdued: #ffffff;
  /* Fallback color */
  --essential-bright-accent: #ffffff;
  /* Fallback color */
  --essential-negative: #ffffff;
  /* Fallback color */
  --essential-warning: #ffffff;
  /* Fallback color */
  --essential-positive: #ffffff;
  /* Fallback color */
  --essential-announcement: #ffffff;
  /* The default decorative color of the overMedia set. */
  --decorative-base: #ffffff;
  /* A subdued variant of the base decorative color. */
  --decorative-subdued: rgba(255, 255, 255, 0.13);
}
.encore-ad-analytics-light-theme .encore-over-media-set > * {
  --parents-essential-base: #ffffff;
}
