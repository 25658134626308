@import '../_deprecated_/old-global.css';

@import './fonts/sportify-circular.css';
@import './variables/base.css';
@import './variables/light.css';
@import './variables/dark.css';


html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  color: var(--text-default);
  background: var(--bg-default);
  /* prettier-ignore */
  font-family: CircularSp, Helvetica, Arial, sans-serif;
  font-size: var(--font-size-body-1);
  height: 100vh;
  line-height: 1.375rem; /* Be carefull changing this, it'll break some styles */
  max-width: 100%;
  min-height: 100vh;
}

#root {
  height: 100%;
}

/* Scroll */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  body *::-webkit-scrollbar {
    width: 0.4375rem;
    height: 0.4375rem;
    background: transparent;
  }

  body *::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
  }

  body *:hover::-webkit-scrollbar-thumb {
    background-color: rgba(100, 100, 100, 0.2);
  }
}

/* Recharts SVG */
.recharts-tooltip-wrapper {
  outline: 0;
}
.recharts-wrapper svg {
  overflow: inherit !important;
}
