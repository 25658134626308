/* CircularSp */
@font-face {
  font-family: CircularSp;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('./CircularSp-Book.woff2') format('woff2');
}

@font-face {
  font-family: CircularSp;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./CircularSp-Book.woff2') format('woff2');
}

@font-face {
  font-family: CircularSp;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('./CircularSp-Medium.woff2') format('woff2');
}

@font-face {
  font-family: CircularSp;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./CircularSp-Bold.woff2') format('woff2');
}

/* CircularSpTitle */
@font-face {
  font-family: CircularSpTitle;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./CircularSpTitle-Bold.woff2') format('woff2');
}

@font-face {
  font-family: CircularSpTitle;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('./CircularSpTitle-Black.woff2') format('woff2');
}
