:root {
  --encore-title-font-stack: CircularSpTitle, CircularSpTitle-Tall,
    CircularSp-Arab, CircularSp-Hebr, CircularSp-Cyrl, CircularSp-Grek,
    CircularSp-Deva, var(--fallback-fonts, sans-serif);

  --encore-body-font-stack: CircularSp, CircularSp-Arab, CircularSp-Hebr,
    CircularSp-Cyrl, CircularSp-Grek, CircularSp-Deva,
    var(--fallback-fonts, sans-serif);
}

.encore-text-headline-large {
  font-size: var(--encore-text-size-larger-5);
  font-family: var(--encore-title-font-stack);
  font-weight: 900;
}
.encore-text-headline-medium {
  font-size: var(--encore-text-size-larger-4);
  font-family: var(--encore-title-font-stack);
  font-weight: 700;
}
.encore-text-title-large {
  font-size: var(--encore-text-size-larger-3);
  font-family: var(--encore-title-font-stack);
  font-weight: 700;
}
.encore-text-title-medium {
  font-size: var(--encore-text-size-larger-2);
  font-family: var(--encore-title-font-stack);
  font-weight: 700;
}
.encore-text-title-small {
  font-size: var(--encore-text-size-larger);
  font-family: var(--encore-title-font-stack);
  font-weight: 700;
}
.encore-text-body-medium {
  font-size: var(--encore-text-size-base);
  font-family: var(--encore-body-font-stack);
  font-weight: 400;
}
.encore-text-body-medium-bold {
  font-size: var(--encore-text-size-base);
  font-family: var(--encore-body-font-stack);
  font-weight: 700;
}
.encore-text-body-small {
  font-size: var(--encore-text-size-smaller);
  font-family: var(--encore-body-font-stack);
  font-weight: 400;
}
.encore-text-body-small-bold {
  font-size: var(--encore-text-size-smaller);
  font-family: var(--encore-body-font-stack);
  font-weight: 700;
}
.encore-text-marginal {
  font-size: var(--encore-text-size-smaller-2);
  font-family: var(--encore-body-font-stack);
  font-weight: 400;
}
.encore-text-marginal-bold {
  font-size: var(--encore-text-size-smaller-2);
  font-family: var(--encore-body-font-stack);
  font-weight: 700;
}
