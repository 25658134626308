html[data-theme='dark'] {
  /* Backgrounds */
  --bg-default: #18191a;
  --bg-subtle: #242526;
  --bg-muted: #242526;
  --bg-surface: #242526;
  --bg-overlay: #252526;
  --bg-tooltip: #21262d;
  /* Background States */
  --bg-default-hover: rgba(255, 255, 255, 0.1);
  --bg-subtle-hover: #161b22;
  --bg-surface-hover: #30363d;
  --bg-nav-hover: rgba(195, 205, 255, 0.25);
  /* Border */
  --border-default: var(--gray-9);
  --border-subtle: var(--gray-7);
  --border-muted: var(--gray-8);
  /* Border States */
  --border-muted-hover: var(--gray-7);
  /* Icons */
  --icon-default: #e4e6eb;
  --icon-subtle: #b0b3b8;
  --icon-muted: #484f58;
  /* Shadow */
  --shadow-sm: 0 0 transparent;
  --shadow-md: 0 1px 4px #010409;
  --shadow-lg: 0 8px 24px #010409;
  --shadow-xl: 0 12px 48px #010409;
  --shadow-focus: 0 0 0 3px #0c2d6b;
  /* Components */
  --table-border-row: rgb(48 54 61 / 50%);
  --counter-bg-1: #6e7681;
  --counter-text-1: #c9d1d9;
  /* More Base Colors */
  --text-on-inverse: #24292e;
  --inverse: #e4e6eb;
  /* Text */
  --text-default: var(--gray-3);
  --text-subtle: var(--gray-4);
  --text-muted: var(--gray-7);
  --text-placeholder: var(--gray-7);
  --text-disabled: rgba(255, 255, 255, 0.3);
  --text-tooltip: #f0f6fc;
  /* Components */
  --table-header-bg: var(--gray-8);
}
