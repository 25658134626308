html {
  /* Backgrounds */
  --bg-default: var(--white);
  --bg-subtle: var(--gray-1);
  --bg-muted: var(--gray-3);
  --bg-surface: var(--white);
  --bg-overlay: var(--white);
  --bg-tooltip: #24292e;
  /* Background States */
  --bg-default-hover: var(--gray-2);
  --bg-subtle-hover: #f8f8f9;
  --bg-surface-hover: var(--gray-1);
  --bg-nav-hover: rgba(195, 205, 255, 0.25);
  /* Border */
  --border-default: var(--gray-3);
  --border-subtle: var(--gray-4);
  --border-muted: var(--gray-5);
  /* Border States */
  --border-subtle-hover: var(--gray-6);
  --border-muted-hover: var(--gray-7);
  /* Icons */
  --icon-default: var(--gray-9);
  --icon-subtle: var(--gray-8);
  --icon-muted: var(--gray-7);
  /* Shadow */
  --shadow-sm: 0 1px 0 rgba(27, 31, 35, 0.04);
  --shadow-md: 0 1px 4px 2px rgba(149, 157, 165, 0.33);
  --shadow-lg: 0 8px 24px rgba(149, 157, 165, 0.2);
  --shadow-xl: 0 12px 48px rgba(149, 157, 165, 0.3);
  --shadow-focus: 0 0 0 3px rgba(3, 102, 214, 0.3);
  /* Components */
  --table-border-row: var(--gray-3);
  --counter-bg-1: #6a737d;
  --counter-text-1: #fff;
  /* More Base Colors */
  --inverse: #24292e;
  --text-on-inverse: var(--white);
  /* Text */
  --text-default: var(--gray-10);
  --text-subtle: var(--gray-9);
  --text-muted: var(--gray-8);
  --text-placeholder: var(--gray-7);
  --text-disabled: var(--gray-5);
  --text-tooltip: var(--white);
  /* Components */
  --table-header-bg: var(--gray-1);
}
