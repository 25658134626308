/* START OLD-CSS */
html,
button,
input,
select,
textarea {
  font-family: inherit;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

progress {
  vertical-align: baseline;
}

template {
  display: none;
}

svg:not(:root) {
  overflow: hidden;
}

b,
strong {
  font-weight: 500;
}

p {
  margin: 0 0 20px 0;
  line-height: 1.5;
}

a {
  text-decoration: underline;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

/* prettier-ignore */
h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  margin: 0;
}

h1 {
  font-size: 32px;
  font-size: 2rem;
  line-height: 2.375rem;
}

h2 {
  font-size: 26px;
  font-size: 1.625rem;
  line-height: 2rem;
}

h3 {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.75rem;
}

h4 {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

h5 {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.375rem;
}

h6 {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

b,
strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

ul,
ol {
  margin: 20px 0;
  padding: 0 0 0 40px;
}

dl:before,
dl:after {
  content: ' ';
  display: table;
}

dl:after {
  clear: both;
}

dl dt {
  float: left;
  width: 25%;
  display: block;
  font-weight: 400;
}

dl dd {
  overflow: hidden;
  display: block;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

label,
button,
optgroup,
select,
textarea {
  font: inherit;
  margin: 0;
  border-radius: 0;
  color: inherit;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='date']::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}

[type='checkbox'],
[type='radio'] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

button[disabled],
html input[disabled] {
  cursor: not-allowed;
}

input::-webkit-input-placeholder {
  color: #999;
}

input:-moz-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
}

input:-ms-input-placeholder {
  color: #999;
}

.transcript-word {
  background: #fff;
}

.transcript-word:hover,
.transcript-word.active {
  background: rgba(255, 165, 0, 0.7);
}

.transcript-word.highlight {
  background: rgba(255, 255, 0, 0.7);
}

img {
  max-width: 100%;
}
/* END OLD-CSS */
