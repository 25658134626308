.encore-ad-analytics-light-theme, .encore-ad-analytics-light-theme .encore-base-set {
  --background-base: #fff;
  --background-highlight: #f6f6f6;
  --background-press: #b7b7b7;
  --background-elevated-base: #fff;
  --background-elevated-highlight: #f6f6f6;
  --background-elevated-press: #b7b7b7;
  --background-tinted-base: #00000014;
  --background-tinted-highlight: #0000000d;
  --background-tinted-press: #0000001f;
  --text-base: #000;
  --text-subdued: #6a6a6a;
  --text-bright-accent: #567408;
  --text-negative: #d31225;
  --text-warning: #9d5a00;
  --text-positive: #117a37;
  --text-announcement: #0c67d3;
  --essential-base: #000;
  --essential-subdued: #878787;
  --essential-bright-accent: #6d930a;
  --essential-negative: #e91429;
  --essential-warning: #c77200;
  --essential-positive: #169b45;
  --essential-announcement: #0d72ea;
  --decorative-base: #000;
  --decorative-subdued: #dedede;
}

.encore-ad-analytics-light-theme > *, .encore-ad-analytics-light-theme .encore-base-set > * {
  --parents-essential-base: #000;
}

.encore-ad-analytics-light-theme .encore-bright-accent-set {
  --background-base: #cff56a;
  --background-highlight: #e2f9a6;
  --background-press: #8bba0c;
  --background-elevated-base: #e2f9a6;
  --background-elevated-highlight: #e2f9a6;
  --background-elevated-press: #8bba0c;
  --background-tinted-base: #cff56a;
  --background-tinted-highlight: #cff56a;
  --background-tinted-press: #cff56a;
  --text-base: #000;
  --text-subdued: #000;
  --text-bright-accent: #000;
  --text-negative: #000;
  --text-warning: #000;
  --text-positive: #000;
  --text-announcement: #000;
  --essential-base: #000;
  --essential-subdued: #000;
  --essential-bright-accent: #000;
  --essential-negative: #000;
  --essential-warning: #000;
  --essential-positive: #000;
  --essential-announcement: #000;
  --decorative-base: #000;
  --decorative-subdued: #a8e20f;
}

.encore-ad-analytics-light-theme .encore-bright-accent-set > * {
  --parents-essential-base: #000;
}

.encore-ad-analytics-light-theme .encore-negative-set {
  --background-base: #e91429;
  --background-highlight: #de1327;
  --background-press: #920d1a;
  --background-elevated-base: #de1327;
  --background-elevated-highlight: #de1327;
  --background-elevated-press: #920d1a;
  --background-tinted-base: #e91429;
  --background-tinted-highlight: #e91429;
  --background-tinted-press: #e91429;
  --text-base: #fff;
  --text-subdued: #fff;
  --text-bright-accent: #fff;
  --text-negative: #fff;
  --text-warning: #fff;
  --text-positive: #fff;
  --text-announcement: #fff;
  --essential-base: #fff;
  --essential-subdued: #fff;
  --essential-bright-accent: #fff;
  --essential-negative: #fff;
  --essential-warning: #fff;
  --essential-positive: #fff;
  --essential-announcement: #fff;
  --decorative-base: #fff;
  --decorative-subdued: #ee3a4c;
}

.encore-ad-analytics-light-theme .encore-negative-set > * {
  --parents-essential-base: #fff;
}

.encore-ad-analytics-light-theme .encore-warning-set {
  --background-base: #ffa42b;
  --background-highlight: #ffaf45;
  --background-press: #c57100;
  --background-elevated-base: #ffaf45;
  --background-elevated-highlight: #ffaf45;
  --background-elevated-press: #c57100;
  --background-tinted-base: #ffa42b;
  --background-tinted-highlight: #ffa42b;
  --background-tinted-press: #ffa42b;
  --text-base: #000;
  --text-subdued: #000;
  --text-bright-accent: #000;
  --text-negative: #000;
  --text-warning: #000;
  --text-positive: #000;
  --text-announcement: #000;
  --essential-base: #000;
  --essential-subdued: #000;
  --essential-bright-accent: #000;
  --essential-negative: #000;
  --essential-warning: #000;
  --essential-positive: #000;
  --essential-announcement: #000;
  --decorative-base: #000;
  --decorative-subdued: #f18900;
}

.encore-ad-analytics-light-theme .encore-warning-set > * {
  --parents-essential-base: #000;
}

.encore-ad-analytics-light-theme .encore-positive-set {
  --background-base: #1ed760;
  --background-highlight: #1fdf64;
  --background-press: #169c46;
  --background-elevated-base: #1fdf64;
  --background-elevated-highlight: #1fdf64;
  --background-elevated-press: #169c46;
  --background-tinted-base: #1ed760;
  --background-tinted-highlight: #1ed760;
  --background-tinted-press: #1ed760;
  --text-base: #000;
  --text-subdued: #000;
  --text-bright-accent: #000;
  --text-negative: #000;
  --text-warning: #000;
  --text-positive: #000;
  --text-announcement: #000;
  --essential-base: #000;
  --essential-subdued: #000;
  --essential-bright-accent: #000;
  --essential-negative: #000;
  --essential-warning: #000;
  --essential-positive: #000;
  --essential-announcement: #000;
  --decorative-base: #000;
  --decorative-subdued: #1abc54;
}

.encore-ad-analytics-light-theme .encore-positive-set > * {
  --parents-essential-base: #000;
}

.encore-ad-analytics-light-theme .encore-announcement-set {
  --background-base: #0d72ea;
  --background-highlight: #0c6ddf;
  --background-press: #084791;
  --background-elevated-base: #0c6ddf;
  --background-elevated-highlight: #0c6ddf;
  --background-elevated-press: #084791;
  --background-tinted-base: #0d72ea;
  --background-tinted-highlight: #0d72ea;
  --background-tinted-press: #0d72ea;
  --text-base: #fff;
  --text-subdued: #fff;
  --text-bright-accent: #fff;
  --text-negative: #fff;
  --text-warning: #fff;
  --text-positive: #fff;
  --text-announcement: #fff;
  --essential-base: #fff;
  --essential-subdued: #fff;
  --essential-bright-accent: #fff;
  --essential-negative: #fff;
  --essential-warning: #fff;
  --essential-positive: #fff;
  --essential-announcement: #fff;
  --decorative-base: #fff;
  --decorative-subdued: #2a86f3;
}

.encore-ad-analytics-light-theme .encore-announcement-set > * {
  --parents-essential-base: #fff;
}

.encore-ad-analytics-light-theme .encore-inverted-dark-set {
  --background-base: #000;
  --background-highlight: #0d0d0d;
  --background-press: #3f3f3f;
  --background-elevated-base: #0d0d0d;
  --background-elevated-highlight: #0d0d0d;
  --background-elevated-press: #3f3f3f;
  --background-tinted-base: #000;
  --background-tinted-highlight: #000;
  --background-tinted-press: #000;
  --text-base: #fff;
  --text-subdued: #8f8f8f;
  --text-bright-accent: #cff56a;
  --text-negative: #fff;
  --text-warning: #fff;
  --text-positive: #fff;
  --text-announcement: #fff;
  --essential-base: #fff;
  --essential-subdued: #5e5e5e;
  --essential-bright-accent: #cff56a;
  --essential-negative: #fff;
  --essential-warning: #fff;
  --essential-positive: #fff;
  --essential-announcement: #fff;
  --decorative-base: #fff;
  --decorative-subdued: #1f1f1f;
}

.encore-ad-analytics-light-theme .encore-inverted-dark-set > * {
  --parents-essential-base: #fff;
}

.encore-ad-analytics-light-theme .encore-inverted-light-set {
  --background-base: #fff;
  --background-highlight: #f6f6f6;
  --background-press: #b7b7b7;
  --background-elevated-base: #f6f6f6;
  --background-elevated-highlight: #f6f6f6;
  --background-elevated-press: #b7b7b7;
  --background-tinted-base: #fff;
  --background-tinted-highlight: #fff;
  --background-tinted-press: #fff;
  --text-base: #000;
  --text-subdued: #5e5e5e;
  --text-bright-accent: #5c7c08;
  --text-negative: #000;
  --text-warning: #000;
  --text-positive: #000;
  --text-announcement: #000;
  --essential-base: #000;
  --essential-subdued: #8f8f8f;
  --essential-bright-accent: #749c0a;
  --essential-negative: #000;
  --essential-warning: #000;
  --essential-positive: #000;
  --essential-announcement: #000;
  --decorative-base: #000;
  --decorative-subdued: #dedede;
}

.encore-ad-analytics-light-theme .encore-inverted-light-set > * {
  --parents-essential-base: #000;
}

.encore-ad-analytics-light-theme .encore-muted-accent-set {
  --background-base: #121212;
  --background-highlight: #1a1a1a;
  --background-press: #000;
  --background-tinted-base: #ffffff12;
  --background-tinted-highlight: #ffffff1a;
  --background-tinted-press: #ffffff0a;
  --background-elevated-base: #1a1a1a;
  --background-elevated-highlight: #1a1a1a;
  --background-elevated-press: #000;
  --text-base: #fff;
  --text-subdued: #a7a7a7;
  --text-bright-accent: #cff56a;
  --text-negative: #fff;
  --text-warning: #fff;
  --text-positive: #fff;
  --text-announcement: #fff;
  --essential-base: #fff;
  --essential-subdued: #727272;
  --essential-bright-accent: #cff56a;
  --essential-negative: #fff;
  --essential-warning: #fff;
  --essential-positive: #fff;
  --essential-announcement: #fff;
  --decorative-base: #fff;
  --decorative-subdued: #292929;
}

.encore-ad-analytics-light-theme .encore-muted-accent-set > * {
  --parents-essential-base: #fff;
}

.encore-ad-analytics-light-theme .encore-over-media-set {
  --background-base: #0000008a;
  --background-highlight: #0000008f;
  --background-press: #000000b8;
  --background-elevated-base: #0000008f;
  --background-elevated-highlight: #0000008f;
  --background-elevated-press: #000000b8;
  --background-tinted-base: #0000008a;
  --background-tinted-highlight: #0000008a;
  --background-tinted-press: #0000008a;
  --text-base: #fff;
  --text-subdued: #fff;
  --text-bright-accent: #fff;
  --text-negative: #fff;
  --text-warning: #fff;
  --text-positive: #fff;
  --text-announcement: #fff;
  --essential-base: #fff;
  --essential-subdued: #fff;
  --essential-bright-accent: #fff;
  --essential-negative: #fff;
  --essential-warning: #fff;
  --essential-positive: #fff;
  --essential-announcement: #fff;
  --decorative-base: #fff;
  --decorative-subdued: #ffffff21;
}

.encore-ad-analytics-light-theme .encore-over-media-set > * {
  --parents-essential-base: #fff;
}

code[class*="language-"], pre[class*="language-"] {
  color: #000;
  text-shadow: 0 1px #fff;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  tab-size: 4;
  -webkit-hyphens: none;
  hyphens: none;
  background: none;
  font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
  font-size: 1em;
  line-height: 1.5;
}

pre[class*="language-"]::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

pre[class*="language-"] ::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

code[class*="language-"]::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

code[class*="language-"] ::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

pre[class*="language-"]::selection {
  text-shadow: none;
  background: #b3d4fc;
}

pre[class*="language-"] ::selection {
  text-shadow: none;
  background: #b3d4fc;
}

code[class*="language-"]::selection {
  text-shadow: none;
  background: #b3d4fc;
}

code[class*="language-"] ::selection {
  text-shadow: none;
  background: #b3d4fc;
}

@media print {
  code[class*="language-"], pre[class*="language-"] {
    text-shadow: none;
  }
}

pre[class*="language-"] {
  margin: .5em 0;
  padding: 1em;
  overflow: auto;
}

:not(pre) > code[class*="language-"], pre[class*="language-"] {
  background: #f5f2f0;
}

:not(pre) > code[class*="language-"] {
  white-space: normal;
  border-radius: .3em;
  padding: .1em;
}

.token.comment, .token.prolog, .token.doctype, .token.cdata {
  color: #708090;
}

.token.punctuation {
  color: #999;
}

.token.namespace {
  opacity: .7;
}

.token.property, .token.tag, .token.boolean, .token.number, .token.constant, .token.symbol, .token.deleted {
  color: #905;
}

.token.selector, .token.attr-name, .token.string, .token.char, .token.builtin, .token.inserted {
  color: #690;
}

.token.operator, .token.entity, .token.url, .language-css .token.string, .style .token.string {
  color: #9a6e3a;
  background: #ffffff80;
}

.token.atrule, .token.attr-value, .token.keyword {
  color: #07a;
}

.token.function, .token.class-name {
  color: #dd4a68;
}

.token.regex, .token.important, .token.variable {
  color: #e90;
}

.token.important, .token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

pre[class*="language-"].line-numbers {
  counter-reset: linenumber;
  padding-left: 3.8em;
  position: relative;
}

pre[class*="language-"].line-numbers > code {
  white-space: inherit;
  position: relative;
}

.line-numbers .line-numbers-rows {
  pointer-events: none;
  letter-spacing: -1px;
  -webkit-user-select: none;
  user-select: none;
  border-right: 1px solid #999;
  width: 3em;
  font-size: 100%;
  position: absolute;
  top: 0;
  left: -3.8em;
}

.line-numbers-rows > span {
  counter-increment: linenumber;
  display: block;
}

.line-numbers-rows > span:before {
  content: counter(linenumber);
  color: #999;
  text-align: right;
  padding-right: .8em;
  display: block;
}

pre[data-line] {
  padding: 1em 0 1em 3em;
  position: relative;
}

.line-highlight {
  padding: inherit 0;
  pointer-events: none;
  line-height: inherit;
  white-space: pre;
  background: linear-gradient(to right, #997a661a 70%, #997a6600);
  margin-top: 1em;
  position: absolute;
  left: 0;
  right: 0;
}

@media print {
  .line-highlight {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.line-highlight:before, .line-highlight[data-end]:after {
  content: attr(data-start);
  color: #f5f2f0;
  text-align: center;
  vertical-align: .3em;
  text-shadow: none;
  background-color: #997a6666;
  border-radius: 999px;
  min-width: 1em;
  padding: 0 .5em;
  font: bold 65% / 1.5 sans-serif;
  position: absolute;
  top: .4em;
  left: .6em;
  box-shadow: 0 1px #fff;
}

.line-highlight[data-end]:after {
  content: attr(data-end);
  top: auto;
  bottom: .4em;
}

.line-numbers .line-highlight:before, .line-numbers .line-highlight:after {
  content: none;
}

pre[id].linkable-line-numbers span.line-numbers-rows {
  pointer-events: all;
}

pre[id].linkable-line-numbers span.line-numbers-rows > span:before {
  cursor: pointer;
}

pre[id].linkable-line-numbers span.line-numbers-rows > span:hover:before {
  background-color: #80808033;
}

html, button, input, select, textarea {
  font-family: inherit;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

audio, canvas, progress, video {
  display: inline-block;
}

progress {
  vertical-align: baseline;
}

template {
  display: none;
}

svg:not(:root) {
  overflow: hidden;
}

p {
  margin: 0 0 20px;
  line-height: 1.5;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
  text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: 700;
}

h1 {
  font-size: 2rem;
  line-height: 2.375rem;
}

h2 {
  font-size: 1.625rem;
  line-height: 2rem;
}

h3 {
  font-size: 1.375rem;
  line-height: 1.75rem;
}

h4 {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

h5 {
  font-size: 1rem;
  line-height: 1.375rem;
}

h6 {
  font-size: .875rem;
  line-height: 1.25rem;
}

b, strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

ul, ol {
  margin: 20px 0;
  padding: 0 0 0 40px;
}

dl:before, dl:after {
  content: " ";
  display: table;
}

dl:after {
  clear: both;
}

dl dt {
  float: left;
  width: 25%;
  font-weight: 400;
  display: block;
}

dl dd {
  display: block;
  overflow: hidden;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

label, button, optgroup, select, textarea {
  font: inherit;
  color: inherit;
  border-radius: 0;
  margin: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="date"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

button[disabled], html input[disabled] {
  cursor: not-allowed;
}

input::-webkit-input-placeholder {
  color: #999;
}

input:-moz-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
}

input:-ms-input-placeholder {
  color: #999;
}

.transcript-word {
  background: #fff;
}

.transcript-word:hover, .transcript-word.active {
  background: #ffa500b3;
}

.transcript-word.highlight {
  background: #ffff00b3;
}

img {
  max-width: 100%;
}

@font-face {
  font-family: CircularSp;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("CircularSp-Book.468342bb.woff2") format("woff2");
}

@font-face {
  font-family: CircularSp;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("CircularSp-Book.468342bb.woff2") format("woff2");
}

@font-face {
  font-family: CircularSp;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("CircularSp-Medium.f2edf72a.woff2") format("woff2");
}

@font-face {
  font-family: CircularSp;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("CircularSp-Bold.51799f6d.woff2") format("woff2");
}

@font-face {
  font-family: CircularSpTitle;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("CircularSpTitle-Bold.3a5f908b.woff2") format("woff2");
}

@font-face {
  font-family: CircularSpTitle;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("CircularSpTitle-Black.21f59116.woff2") format("woff2");
}

html {
  --white: #fff;
  --black: #000;
  --gray-1: #f6f6f6;
  --gray-2: #f2f3f5;
  --gray-3: #e1e4e8;
  --gray-4: #dadde1;
  --gray-5: #ccd0d5;
  --gray-6: #bec3c9;
  --gray-7: #8d949e;
  --gray-8: #6a6a6a;
  --gray-9: #444950;
  --gray-10: #1c1e21;
  --spotify-yellow: #cdf564;
  --green-podsights: #cdf564;
  --blue-dark: #460e3c;
  --blue: #a1258a;
  --blue-80: #b551a1;
  --blue-60: #c77cb9;
  --blue-40: #d9a8d0;
  --blue-20: #ecd3e8;
  --blue-10: #f6e9f3;
  --green-dark: #00975d;
  --green: #1ed760;
  --green-80: #45e57e;
  --green-60: #73ec9e;
  --green-40: #a2f2bf;
  --green-20: #d0f9df;
  --green-10: #e8fcef;
  --highlighter-dark: #847504;
  --highlighter: #e7fe02;
  --highlighter-80: #e7fe02;
  --highlighter-60: #f1fe67;
  --highlighter-40: #f5ff9a;
  --highlighter-20: #faffcc;
  --highlighter-10: #fdffe6;
  --red-orange-dark: #e12a16;
  --red-orange: #ff4632;
  --red-orange-80: #ff6b5b;
  --red-orange-60: #ff9084;
  --red-orange-40: #ffb5ad;
  --red-orange-20: #ffdad6;
  --red-orange-10: #ffecea;
  --pink: #ff94d5;
  --pink-80: #ffa9dd;
  --pink-60: #ffbfe6;
  --pink-40: #ffd4ee;
  --pink-20: #ffeaf7;
  --pink-10: #fff4fb;
  --default: #bdbdbd;
  --primary: var(--blue);
  --secondary: var(--spotify-yellow);
  --error: var(--red-orange);
  --info: var(--blue);
  --success: #16d0a6;
  --warning: #ffa42b;
  --text-on-primary: var(--white);
  --text-on-secondary: var(--gray-10);
  --text-on-error: var(--white);
  --text-on-info: var(--white);
  --text-on-success: var(--white);
  --text-on-warning: var(--white);
  --text-primary: var(--blue-dark);
  --text-secondary: var(--green-dark);
  --text-error: var(--red-orange-dark);
  --text-success: var(--green-dark);
  --text-info: var(--blue-dark);
  --text-warning: var(--highlighter-dark);
  --font-family-base: CircularSp, Helvetica, Arial, sans-serif;
  --font-family-title: CircularSpTitle, Helvetica, Arial, sans-serif;
  --font-size-h1: 2.5rem;
  --font-size-h2: 1.75rem;
  --font-size-h3: 1.375rem;
  --font-size-h4: 1.125rem;
  --font-size-h5: 1.0625rem;
  --font-size-body-1: 1rem;
  --font-size-body-2: .875rem;
  --font-size-body-3: .8125rem;
  --font-size-body-4: .6875rem;
  --line-height-xs: 1.3;
  --line-height-sm: 1.4;
  --line-height-md: 1.5;
  --line-height-lg: 1.6;
  --line-height-xl: 1.75;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-weight-extrabold: 900;
  --spacing-1: .25rem;
  --spacing-2: .5rem;
  --spacing-3: .75rem;
  --spacing-4: 1rem;
  --spacing-5: 1.25rem;
  --spacing-6: 1.5rem;
  --spacing-7: 1.75rem;
  --spacing-8: 2rem;
  --spacing-9: 2.25rem;
  --spacing-10: 2.5rem;
  --spacing-11: 2.75rem;
  --spacing-12: 3rem;
  --spacing-13: 3.25rem;
  --spacing-14: 3.5rem;
  --spacing-15: 3.75rem;
  --radius-1: .75rem;
  --radius-2: 1.25rem;
  --radius-3: 2rem;
  --radius-4: 3rem;
  --stack-padding-condensed: var(--size-2);
  --stack-padding-normal: var(--size-4);
  --stack-padding-spacious: var(--size-6);
  --stack-gap-condensed: var(--size-2);
  --stack-gap-normal: var(--size-4);
  --stack-gap-spacious: var(--size-6);
  --app-left-nav-width: 13rem;
  --input-height-sm: 2.125rem;
  --input-height-md: 2.625rem;
  --input-border: 1px solid var(--border-muted);
  --input-border-error: 1px solid var(--border-muted);
  --input-border-radius-sm: 3px;
  --input-border-radius-md: 4px;
  --advisor-affinity-color: #953086;
  --advisor-affinity-bg: transparent;
  --advisor-affinity-megaphone-color: #6347ff;
  --advisor-affinity-megaphone-bg: #dfd7fe;
  --advisor-reach-color: #953086;
  --advisor-reach-bg: transparent;
}

html {
  --bg-default: var(--white);
  --bg-subtle: var(--gray-1);
  --bg-muted: var(--gray-3);
  --bg-surface: var(--white);
  --bg-overlay: var(--white);
  --bg-tooltip: #24292e;
  --bg-default-hover: var(--gray-2);
  --bg-subtle-hover: #f8f8f9;
  --bg-surface-hover: var(--gray-1);
  --bg-nav-hover: #c3cdff40;
  --border-default: var(--gray-3);
  --border-subtle: var(--gray-4);
  --border-muted: var(--gray-5);
  --border-subtle-hover: var(--gray-6);
  --border-muted-hover: var(--gray-7);
  --icon-default: var(--gray-9);
  --icon-subtle: var(--gray-8);
  --icon-muted: var(--gray-7);
  --shadow-sm: 0 1px 0 #1b1f230a;
  --shadow-md: 0 1px 4px 2px #959da554;
  --shadow-lg: 0 8px 24px #959da533;
  --shadow-xl: 0 12px 48px #959da54d;
  --shadow-focus: 0 0 0 3px #0366d64d;
  --table-border-row: var(--gray-3);
  --counter-bg-1: #6a737d;
  --counter-text-1: #fff;
  --inverse: #24292e;
  --text-on-inverse: var(--white);
  --text-default: var(--gray-10);
  --text-subtle: var(--gray-9);
  --text-muted: var(--gray-8);
  --text-placeholder: var(--gray-7);
  --text-disabled: var(--gray-5);
  --text-tooltip: var(--white);
  --table-header-bg: var(--gray-1);
}

html[data-theme="dark"] {
  --bg-default: #18191a;
  --bg-subtle: #242526;
  --bg-muted: #242526;
  --bg-surface: #242526;
  --bg-overlay: #252526;
  --bg-tooltip: #21262d;
  --bg-default-hover: #ffffff1a;
  --bg-subtle-hover: #161b22;
  --bg-surface-hover: #30363d;
  --bg-nav-hover: #c3cdff40;
  --border-default: var(--gray-9);
  --border-subtle: var(--gray-7);
  --border-muted: var(--gray-8);
  --border-muted-hover: var(--gray-7);
  --icon-default: #e4e6eb;
  --icon-subtle: #b0b3b8;
  --icon-muted: #484f58;
  --shadow-sm: 0 0 transparent;
  --shadow-md: 0 1px 4px #010409;
  --shadow-lg: 0 8px 24px #010409;
  --shadow-xl: 0 12px 48px #010409;
  --shadow-focus: 0 0 0 3px #0c2d6b;
  --table-border-row: #30363d80;
  --counter-bg-1: #6e7681;
  --counter-text-1: #c9d1d9;
  --text-on-inverse: #24292e;
  --inverse: #e4e6eb;
  --text-default: var(--gray-3);
  --text-subtle: var(--gray-4);
  --text-muted: var(--gray-7);
  --text-placeholder: var(--gray-7);
  --text-disabled: #ffffff4d;
  --text-tooltip: #f0f6fc;
  --table-header-bg: var(--gray-8);
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  color: var(--text-default);
  background: var(--bg-default);
  font-family: CircularSp, Helvetica, Arial, sans-serif;
  font-size: var(--font-size-body-1);
  max-width: 100%;
  height: 100vh;
  min-height: 100vh;
  line-height: 1.375rem;
}

#root {
  height: 100%;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  body ::-webkit-scrollbar {
    background: none;
    width: .4375rem;
    height: .4375rem;
  }

  body ::-webkit-scrollbar-thumb {
    background-color: #0000001a;
    border-radius: 1rem;
  }

  body :hover::-webkit-scrollbar-thumb {
    background-color: #64646433;
  }
}

.recharts-tooltip-wrapper {
  outline: 0;
}

.recharts-wrapper svg {
  overflow: inherit !important;
}

html {
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  tab-size: 4;
  word-break: normal;
}

*, :after, :before {
  box-sizing: inherit;
  background-repeat: no-repeat;
}

:after, :before {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

* {
  margin: 0;
  padding: 0;
}

hr {
  color: inherit;
  height: 0;
  overflow: visible;
}

details, main {
  display: block;
}

summary {
  display: list-item;
}

small {
  font-size: 80%;
}

[hidden] {
  display: none;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

a {
  background-color: #0000;
}

a:active, a:hover {
  outline-width: 0;
}

code, kbd, pre, samp {
  font-family: monospace;
}

pre {
  font-size: 1em;
}

b, strong {
  font-weight: bolder;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  border-color: inherit;
  text-indent: 0;
}

iframe {
  border-style: none;
}

input {
  border-radius: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  resize: vertical;
  overflow: auto;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

optgroup {
  font-weight: 700;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[role="button"], [type="button"], [type="reset"], [type="submit"], button {
  cursor: pointer;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  outline: 1px dotted ButtonText;
}

[type="reset"]::-moz-focus-inner {
  outline: 1px dotted ButtonText;
}

[type="submit"]::-moz-focus-inner {
  outline: 1px dotted ButtonText;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="reset"], [type="submit"], button, html [type="button"] {
  -webkit-appearance: button;
}

button, input, select, textarea {
  background-color: #0000;
  border-style: none;
}

a:focus, button:focus, input:focus, select:focus, textarea:focus {
  outline-width: 0;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

select::-ms-expand {
  display: none;
}

select::-ms-value {
  color: currentColor;
}

legend {
  color: inherit;
  white-space: normal;
  border: 0;
  max-width: 100%;
  display: table;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  color: inherit;
  font: inherit;
}

[disabled] {
  cursor: default;
}

img {
  border-style: none;
}

progress {
  vertical-align: baseline;
}

[aria-busy="true"] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled="true"] {
  cursor: default;
}

@font-face {
  font-family: CircularSp-Deva;
  font-weight: 900;
  font-display: swap;
  unicode-range: U+0, U+D, U+20, U+A0, U+A4, U+B6-B7, U+901-97F, U+200C-200E, U+FFFF;
  src: url("https://encore.scdn.co/fonts/CircularSp-Deva-Black-a0e7d7aa929b3aa7d57e5773563de713.woff2") format("woff2"), url("https://encore.scdn.co/fonts/CircularSp-Deva-Black-bbe191a7814d06a3eecc724265dae2d0.woff") format("woff");
}

@font-face {
  font-family: CircularSp-Deva;
  font-weight: 700;
  font-display: swap;
  unicode-range: U+0, U+D, U+20, U+A0, U+A4, U+B6-B7, U+901-97F, U+200C-200E, U+FFFF;
  src: url("https://encore.scdn.co/fonts/CircularSp-Deva-Bold-4f56f02173de0561d370b2565f87aa1c.woff2") format("woff2"), url("https://encore.scdn.co/fonts/CircularSp-Deva-Bold-a212adfa8f476d3544664463d5759178.woff") format("woff");
}

@font-face {
  font-family: CircularSp-Deva;
  font-weight: 400;
  font-display: swap;
  unicode-range: U+0, U+D, U+20, U+A0, U+A4, U+B6-B7, U+901-97F, U+200C-200E, U+FFFF;
  src: url("https://encore.scdn.co/fonts/CircularSp-Deva-Book-a2baf2e8b66dbbd3f7ff84d69d733ca0.woff2") format("woff2"), url("https://encore.scdn.co/fonts/CircularSp-Deva-Book-159c0e02bc9dc7dd0830fb569faf1069.woff") format("woff");
}

@font-face {
  font-family: CircularSp-Grek;
  font-weight: 900;
  font-display: swap;
  unicode-range: U+0, U+D, U+20, U+A0, U+A4, U+B6-B7, U+375, U+37A, U+384, U+386, U+388-38A, U+38C, U+38E-3A1, U+3A3-3CF, U+3D7, U+2126, U+FFFF;
  src: url("https://encore.scdn.co/fonts/CircularSp-Grek-Black-b45055a950eac7560342cb7fa07559ad.woff2") format("woff2"), url("https://encore.scdn.co/fonts/CircularSp-Grek-Black-49883536c1a3bfc688235ce40572731d.woff") format("woff");
}

@font-face {
  font-family: CircularSp-Grek;
  font-weight: 700;
  font-display: swap;
  unicode-range: U+0, U+D, U+20, U+A0, U+A4, U+B6-B7, U+375, U+37A, U+384, U+386, U+388-38A, U+38C, U+38E-3A1, U+3A3-3CF, U+3D7, U+2126, U+FFFF;
  src: url("https://encore.scdn.co/fonts/CircularSp-Grek-Bold-fafb1410c85fe7e6e81c68e609d4b823.woff2") format("woff2"), url("https://encore.scdn.co/fonts/CircularSp-Grek-Bold-53bb923248ba22cf5554bbe5f434c5c8.woff") format("woff");
}

@font-face {
  font-family: CircularSp-Grek;
  font-weight: 400;
  font-display: swap;
  unicode-range: U+0, U+D, U+20, U+A0, U+A4, U+B6-B7, U+375, U+37A, U+384, U+386, U+388-38A, U+38C, U+38E-3A1, U+3A3-3CF, U+3D7, U+2126, U+FFFF;
  src: url("https://encore.scdn.co/fonts/CircularSp-Grek-Book-5d5a3a10aa26b63a2575ce872ad43f07.woff2") format("woff2"), url("https://encore.scdn.co/fonts/CircularSp-Grek-Book-c9de2c0741586c1ab7b6e95541fc7807.woff") format("woff");
}

@font-face {
  font-family: CircularSpTitle;
  font-weight: 900;
  font-display: swap;
  unicode-range: U+0, U+20-7E, U+A0-BF, U+C6-C7, U+D0, U+D7, U+DE-E4, U+E6-EF, U+F1-FF, U+101, U+103-105, U+107, U+109, U+10B, U+10D, U+10F-111, U+113, U+115, U+117-119, U+11B, U+11D, U+11F, U+121, U+126-127, U+129, U+12B, U+12D-12F, U+131-133, U+135, U+138, U+13E-142, U+144, U+148, U+14A-14B, U+14D, U+14F, U+151-153, U+155, U+159, U+15B, U+15D-15F, U+161-163, U+166-167, U+169, U+16B, U+16D, U+171-173, U+175, U+177, U+17A, U+17C, U+17E, U+18F, U+192, U+1A1, U+1B0, U+1B5-1B6, U+1C5-1C6, U+1F1-1F3, U+1FD, U+1FF, U+237, U+259, U+2C6-2C7, U+2C9, U+2D8-2D9, U+2DB-2DD, U+394, U+3A9, U+3BC, U+3C0, U+1E81, U+1E83, U+1E85, U+1E8B, U+1EA0-1EA1, U+1EAD, U+1EB7-1EB9, U+1EBD, U+1EC7, U+1ECA-1ECD, U+1ED9, U+1EDB, U+1EDD, U+1EE3-1EE5, U+1EE9, U+1EEB, U+1EF1, U+1EF3-1EF5, U+1EF9, U+1FD6, U+2007-2008, U+200B, U+2010-2011, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2032-2033, U+2039-203A, U+2042, U+2044, U+2051, U+2070, U+2074-2079, U+2080-2089, U+20AB-20AC, U+2113, U+2117, U+2122, U+2126, U+2160-2169, U+216C-216F, U+2190-2193, U+2196-2199, U+21A9, U+21B0-21B5, U+21C6, U+2202, U+2206, U+220F, U+2211-2212, U+2215, U+2219, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+22C5, U+24C5, U+25A0-25A1, U+25AF, U+25B2-25B3, U+25CA-25CB, U+25CF, U+262E, U+2713, U+2715, U+2780-2788, U+E000, U+E002, U+FB00-FB04, U+FEFF, U+FF0C, U+FF0E, U+FF1A-FF1B, U+FFFF;
  src: url("https://encore.scdn.co/fonts/CircularSpTitle-Black-3f9afb402080d53345ca1850226ca724.woff2") format("woff2"), url("https://encore.scdn.co/fonts/CircularSpTitle-Black-b063ad0a42cbaf519c7ccba8b3e1caeb.woff") format("woff");
}

@font-face {
  font-family: CircularSpTitle;
  font-weight: 700;
  font-display: swap;
  unicode-range: U+0, U+20-7E, U+A0-BF, U+C6-C7, U+D0, U+D7, U+DE-E4, U+E6-EF, U+F1-FF, U+101, U+103-105, U+107, U+109, U+10B, U+10D, U+10F-111, U+113, U+115, U+117-119, U+11B, U+11D, U+11F, U+121, U+126-127, U+129, U+12B, U+12D-12F, U+131-133, U+135, U+138, U+13E-142, U+144, U+148, U+14A-14B, U+14D, U+14F, U+151-153, U+155, U+159, U+15B, U+15D-15F, U+161-163, U+166-167, U+169, U+16B, U+16D, U+171-173, U+175, U+177, U+17A, U+17C, U+17E, U+18F, U+192, U+1A1, U+1B0, U+1B5-1B6, U+1C5-1C6, U+1F1-1F3, U+1FD, U+1FF, U+237, U+259, U+2C6-2C7, U+2C9, U+2D8-2D9, U+2DB-2DD, U+394, U+3A9, U+3BC, U+3C0, U+1E81, U+1E83, U+1E85, U+1E8B, U+1EA0-1EA1, U+1EAD, U+1EB7-1EB9, U+1EBD, U+1EC7, U+1ECA-1ECD, U+1ED9, U+1EDB, U+1EDD, U+1EE3-1EE5, U+1EE9, U+1EEB, U+1EF1, U+1EF3-1EF5, U+1EF9, U+1FD6, U+2007-2008, U+200B, U+2010-2011, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2032-2033, U+2039-203A, U+2042, U+2044, U+2051, U+2070, U+2074-2079, U+2080-2089, U+20AB-20AC, U+2113, U+2117, U+2122, U+2126, U+2160-2169, U+216C-216F, U+2190-2193, U+2196-2199, U+21A9, U+21B0-21B5, U+21C6, U+2202, U+2206, U+220F, U+2211-2212, U+2215, U+2219, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+22C5, U+24C5, U+25A0-25A1, U+25AF, U+25B2-25B3, U+25CA-25CB, U+25CF, U+262E, U+2713, U+2715, U+2780-2788, U+E000, U+E002, U+FB00-FB04, U+FEFF, U+FF0C, U+FF0E, U+FF1A-FF1B, U+FFFF;
  src: url("https://encore.scdn.co/fonts/CircularSpTitle-Bold-2fbf72b606d7f0b0f771ea4956a8b4d6.woff2") format("woff2"), url("https://encore.scdn.co/fonts/CircularSpTitle-Bold-7cd3208b73fd0850bca5d8f7821439cd.woff") format("woff");
}

@font-face {
  font-family: CircularSpTitle-Tall;
  font-weight: 900;
  font-display: swap;
  unicode-range: U+0, U+20, U+A0, U+A4, U+B6-B7, U+C0-C5, U+C8-CF, U+D1-D6, U+D8-DD, U+E5, U+F0, U+100, U+102, U+106, U+108, U+10A, U+10C, U+10E, U+112, U+114, U+116, U+11A, U+11C, U+11E, U+120, U+122-125, U+128, U+12A, U+12C, U+130, U+134, U+136-137, U+139-13D, U+143, U+145-147, U+149, U+14C, U+14E, U+150, U+154, U+156-158, U+15A, U+15C, U+160, U+164-165, U+168, U+16A, U+16C, U+16E-170, U+174, U+176, U+178-179, U+17B, U+17D, U+1A0, U+1AF, U+1C4, U+1FA-1FC, U+1FE, U+218-21B, U+2BB-2BC, U+2DA, U+300-301, U+303, U+309, U+323, U+1E80, U+1E82, U+1E84, U+1E8A, U+1EA2-1EAC, U+1EAE-1EB6, U+1EBA-1EBC, U+1EBE-1EC6, U+1EC8-1EC9, U+1ECE-1ED8, U+1EDA, U+1EDC, U+1EDE-1EE2, U+1EE6-1EE8, U+1EEA, U+1EEC-1EF0, U+1EF2, U+1EF6-1EF8, U+221A, U+F6C3, U+FFFF;
  src: url("https://encore.scdn.co/fonts/CircularSpTitle-Tall-Black-dd3ec8c5997b5c7135fb3716866ded28.woff2") format("woff2"), url("https://encore.scdn.co/fonts/CircularSpTitle-Tall-Black-35a438fa59bc07bdf08133e438d72409.woff") format("woff");
}

@font-face {
  font-family: CircularSpTitle-Tall;
  font-weight: 700;
  font-display: swap;
  unicode-range: U+0, U+20, U+A0, U+A4, U+B6-B7, U+C0-C5, U+C8-CF, U+D1-D6, U+D8-DD, U+E5, U+F0, U+100, U+102, U+106, U+108, U+10A, U+10C, U+10E, U+112, U+114, U+116, U+11A, U+11C, U+11E, U+120, U+122-125, U+128, U+12A, U+12C, U+130, U+134, U+136-137, U+139-13D, U+143, U+145-147, U+149, U+14C, U+14E, U+150, U+154, U+156-158, U+15A, U+15C, U+160, U+164-165, U+168, U+16A, U+16C, U+16E-170, U+174, U+176, U+178-179, U+17B, U+17D, U+1A0, U+1AF, U+1C4, U+1FA-1FC, U+1FE, U+218-21B, U+2BB-2BC, U+2DA, U+300-301, U+303, U+309, U+323, U+1E80, U+1E82, U+1E84, U+1E8A, U+1EA2-1EAC, U+1EAE-1EB6, U+1EBA-1EBC, U+1EBE-1EC6, U+1EC8-1EC9, U+1ECE-1ED8, U+1EDA, U+1EDC, U+1EDE-1EE2, U+1EE6-1EE8, U+1EEA, U+1EEC-1EF0, U+1EF2, U+1EF6-1EF8, U+221A, U+F6C3, U+FFFF;
  src: url("https://encore.scdn.co/fonts/CircularSpTitle-Tall-Bold-69995af8079e90f1d15130df13ad0c60.woff2") format("woff2"), url("https://encore.scdn.co/fonts/CircularSpTitle-Tall-Bold-24c7e518b6e633e498760fab4aa88218.woff") format("woff");
}

@font-face {
  font-family: CircularSp;
  font-weight: 700;
  font-display: swap;
  unicode-range: U+0, U+D, U+20-7E, U+A0-17E, U+18F, U+192, U+1A0-1A1, U+1AF-1B0, U+1B5-1B6, U+1C4-1C6, U+1F1-1F3, U+1FA-1FF, U+218-21B, U+237, U+259, U+2BB-2BC, U+2C6-2C7, U+2C9, U+2D8-2DD, U+300-301, U+303, U+309, U+323, U+394, U+3A9, U+3BC, U+3C0, U+1E80-1E85, U+1E8A-1E8B, U+1EA0-1EF9, U+1FD6, U+2007-2008, U+200B, U+2010-2011, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2032-2033, U+2039-203A, U+2042, U+2044, U+2051, U+2070, U+2074-2079, U+2080-2089, U+20AB-20AC, U+2113, U+2117, U+2122, U+2126, U+2160-2169, U+216C-216F, U+2190-2193, U+2196-2199, U+21A9, U+21B0-21B5, U+21C6, U+2202, U+2206, U+220F, U+2211-2212, U+2215, U+2219-221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+22C5, U+24C5, U+25A0-25A1, U+25AF, U+25B2-25B3, U+25CA-25CB, U+25CF, U+262E, U+2713, U+2715, U+2780-2788, U+E000, U+E002, U+F6C3, U+FB00-FB04, U+FEFF, U+FF0C, U+FF0E, U+FF1A-FF1B, U+FFFF;
  src: url("https://encore.scdn.co/fonts/CircularSp-Bold-fe1cfc14b7498b187c78fa72fb72d148.woff2") format("woff2"), url("https://encore.scdn.co/fonts/CircularSp-Bold-79ebb2bdea919cebedea4884d08c499e.woff") format("woff");
}

@font-face {
  font-family: CircularSp;
  font-weight: 400;
  font-display: swap;
  unicode-range: U+0, U+D, U+20-7E, U+A0-17E, U+18F, U+192, U+1A0-1A1, U+1AF-1B0, U+1B5-1B6, U+1C4-1C6, U+1F1-1F3, U+1FA-1FF, U+218-21B, U+237, U+259, U+2BB-2BC, U+2C6-2C7, U+2C9, U+2D8-2DD, U+300-301, U+303, U+309, U+323, U+394, U+3A9, U+3BC, U+3C0, U+1E80-1E85, U+1E8A-1E8B, U+1EA0-1EF9, U+1FD6, U+2007-2008, U+200B, U+2010-2011, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2032-2033, U+2039-203A, U+2042, U+2044, U+2051, U+2070, U+2074-2079, U+2080-2089, U+20AB-20AC, U+2113, U+2117, U+2122, U+2126, U+2160-2169, U+216C-216F, U+2190-2193, U+2196-2199, U+21A9, U+21B0-21B5, U+21C6, U+2202, U+2206, U+220F, U+2211-2212, U+2215, U+2219-221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+22C5, U+24C5, U+25A0-25A1, U+25AF, U+25B2-25B3, U+25CA-25CB, U+25CF, U+262E, U+2713, U+2715, U+2780-2788, U+E000, U+E002, U+F6C3, U+FB00-FB04, U+FEFF, U+FF0C, U+FF0E, U+FF1A-FF1B, U+FFFF;
  src: url("https://encore.scdn.co/fonts/CircularSp-Book-4eaffdf96f4c6f984686e93d5d9cb325.woff2") format("woff2"), url("https://encore.scdn.co/fonts/CircularSp-Book-4f217aebee53ffc72f4444f79041ffa5.woff") format("woff");
}

@font-face {
  font-family: CircularSp-Arab;
  font-weight: 900;
  font-display: swap;
  unicode-range: U+0, U+D, U+20, U+A0, U+A4, U+B6-B7, U+1C4-1C6, U+1F1-1F3, U+2BB-2BC, U+600-603, U+60B-60C, U+610, U+61B, U+61F, U+621-63A, U+640-659, U+660-671, U+679-681, U+683-68A, U+68C-68D, U+68F, U+691, U+693, U+696, U+698-69A, U+6A1, U+6A4, U+6A6, U+6A9-6AB, U+6AF, U+6B1, U+6B3, U+6BA-6BC, U+6BE, U+6C0-6C3, U+6CC-6CD, U+6D0, U+6D2-6D5, U+6F0-6F9, U+6FD-6FE, U+2002-2003, U+2009, U+200C-200F, U+25CC, U+FB50-FB85, U+FB88-FBBB, U+FBBD-FBC1, U+FBE4-FBE9, U+FBFC-FBFF, U+FC48, U+FC5E-FC63, U+FD3E-FD3F, U+FDF2, U+FDFC, U+FE80-FEFC, U+FFFF;
  src: url("https://encore.scdn.co/fonts/CircularSp-Arab-Black-45efa22567f23866c34554b4a118b7eb.woff2") format("woff2"), url("https://encore.scdn.co/fonts/CircularSp-Arab-Black-85253c80e7df46d62c4d5420e1cc5f4c.woff") format("woff");
}

@font-face {
  font-family: CircularSp-Arab;
  font-weight: 700;
  font-display: swap;
  unicode-range: U+0, U+D, U+20, U+A0, U+A4, U+B6-B7, U+1C4-1C6, U+1F1-1F3, U+2BB-2BC, U+600-603, U+60B-60C, U+610, U+61B, U+61F, U+621-63A, U+640-659, U+660-671, U+679-681, U+683-68A, U+68C-68D, U+68F, U+691, U+693, U+696, U+698-69A, U+6A1, U+6A4, U+6A6, U+6A9-6AB, U+6AF, U+6B1, U+6B3, U+6BA-6BC, U+6BE, U+6C0-6C3, U+6CC-6CD, U+6D0, U+6D2-6D5, U+6F0-6F9, U+6FD-6FE, U+2002-2003, U+2009, U+200C-200F, U+25CC, U+FB50-FB85, U+FB88-FBBB, U+FBBD-FBC1, U+FBE4-FBE9, U+FBFC-FBFF, U+FC48, U+FC5E-FC63, U+FD3E-FD3F, U+FDF2, U+FDFC, U+FE80-FEFC, U+FFFF;
  src: url("https://encore.scdn.co/fonts/CircularSp-Arab-Bold-55131a638ddbb83219f034dab0c4f2ef.woff2") format("woff2"), url("https://encore.scdn.co/fonts/CircularSp-Arab-Bold-f6f9a0b56c26078440d40b145f48ab5a.woff") format("woff");
}

@font-face {
  font-family: CircularSp-Arab;
  font-weight: 400;
  font-display: swap;
  unicode-range: U+0, U+D, U+20, U+A0, U+A4, U+B6-B7, U+1C4-1C6, U+1F1-1F3, U+2BB-2BC, U+600-603, U+60B-60C, U+610, U+61B, U+61F, U+621-63A, U+640-659, U+660-671, U+679-681, U+683-68A, U+68C-68D, U+68F, U+691, U+693, U+696, U+698-69A, U+6A1, U+6A4, U+6A6, U+6A9-6AB, U+6AF, U+6B1, U+6B3, U+6BA-6BC, U+6BE, U+6C0-6C3, U+6CC-6CD, U+6D0, U+6D2-6D5, U+6F0-6F9, U+6FD-6FE, U+2002-2003, U+2009, U+200C-200F, U+25CC, U+FB50-FB85, U+FB88-FBBB, U+FBBD-FBC1, U+FBE4-FBE9, U+FBFC-FBFF, U+FC48, U+FC5E-FC63, U+FD3E-FD3F, U+FDF2, U+FDFC, U+FE80-FEFC, U+FFFF;
  src: url("https://encore.scdn.co/fonts/CircularSp-Arab-Book-2cf93eecef4be930c68c114d308d76c2.woff2") format("woff2"), url("https://encore.scdn.co/fonts/CircularSp-Arab-Book-15ab1b42ca6ef5894519756031ee3ed5.woff") format("woff");
}

@font-face {
  font-family: CircularSp-Cyrl;
  font-weight: 900;
  font-display: swap;
  unicode-range: U+0, U+D, U+20, U+A0, U+A4, U+B6-B7, U+400-45F, U+462-463, U+472-475, U+490-493, U+49A-49B, U+4A2-4A3, U+4AE-4B3, U+4BA-4BB, U+4D8-4D9, U+4E8-4E9, U+FFFF;
  src: url("https://encore.scdn.co/fonts/CircularSp-Cyrl-Black-1ce6672693e92d2cb1d04af1690e1f9c.woff2") format("woff2"), url("https://encore.scdn.co/fonts/CircularSp-Cyrl-Black-b4305d9bf82554f631d2636c3ef90c54.woff") format("woff");
}

@font-face {
  font-family: CircularSp-Cyrl;
  font-weight: 700;
  font-display: swap;
  unicode-range: U+0, U+D, U+20, U+A0, U+A4, U+B6-B7, U+400-45F, U+462-463, U+472-475, U+490-493, U+49A-49B, U+4A2-4A3, U+4AE-4B3, U+4BA-4BB, U+4D8-4D9, U+4E8-4E9, U+FFFF;
  src: url("https://encore.scdn.co/fonts/CircularSp-Cyrl-Bold-180db050d109afeef4331b1ee551c349.woff2") format("woff2"), url("https://encore.scdn.co/fonts/CircularSp-Cyrl-Bold-7e54bccaf45728c472079785d5cd4519.woff") format("woff");
}

@font-face {
  font-family: CircularSp-Cyrl;
  font-weight: 400;
  font-display: swap;
  unicode-range: U+0, U+D, U+20, U+A0, U+A4, U+B6-B7, U+400-45F, U+462-463, U+472-475, U+490-493, U+49A-49B, U+4A2-4A3, U+4AE-4B3, U+4BA-4BB, U+4D8-4D9, U+4E8-4E9, U+FFFF;
  src: url("https://encore.scdn.co/fonts/CircularSp-Cyrl-Book-cc791a2e69e25c0440792bd855600ca7.woff2") format("woff2"), url("https://encore.scdn.co/fonts/CircularSp-Cyrl-Book-6f078f781ee313e298ad8997fd1ffe3d.woff") format("woff");
}

@font-face {
  font-family: CircularSp-Hebr;
  font-weight: 900;
  font-display: swap;
  unicode-range: U+0, U+D, U+20, U+30-39, U+A0, U+A4, U+B6-B7, U+1C4-1C6, U+1F1-1F3, U+2BB-2BC, U+5B0-5C4, U+5D0-5EA, U+5F0-5F4, U+200E-200F, U+20AA, U+FB2A-FB36, U+FB38-FB3C, U+FB3E, U+FB40-FB41, U+FB43-FB44, U+FB46-FB4F, U+FFFF;
  src: url("https://encore.scdn.co/fonts/CircularSp-Hebr-Black-29a8af6f5782cdf3009a5ac966ee4fa6.woff2") format("woff2"), url("https://encore.scdn.co/fonts/CircularSp-Hebr-Black-f52613a9d541248805af1d0bb33102f8.woff") format("woff");
}

@font-face {
  font-family: CircularSp-Hebr;
  font-weight: 700;
  font-display: swap;
  unicode-range: U+0, U+D, U+20, U+30-39, U+A0, U+A4, U+B6-B7, U+1C4-1C6, U+1F1-1F3, U+2BB-2BC, U+5B0-5C4, U+5D0-5EA, U+5F0-5F4, U+200E-200F, U+20AA, U+FB2A-FB36, U+FB38-FB3C, U+FB3E, U+FB40-FB41, U+FB43-FB44, U+FB46-FB4F, U+FFFF;
  src: url("https://encore.scdn.co/fonts/CircularSp-Hebr-Bold-9b609e9268a3f9d73732dc3cc375821e.woff2") format("woff2"), url("https://encore.scdn.co/fonts/CircularSp-Hebr-Bold-caa03e4cb8690e726ef1625c7d91a7a5.woff") format("woff");
}

@font-face {
  font-family: CircularSp-Hebr;
  font-weight: 400;
  font-display: swap;
  unicode-range: U+0, U+D, U+20, U+30-39, U+A0, U+A4, U+B6-B7, U+1C4-1C6, U+1F1-1F3, U+2BB-2BC, U+5B0-5C4, U+5D0-5EA, U+5F0-5F4, U+200E-200F, U+20AA, U+FB2A-FB36, U+FB38-FB3C, U+FB3E, U+FB40-FB41, U+FB43-FB44, U+FB46-FB4F, U+FFFF;
  src: url("https://encore.scdn.co/fonts/CircularSp-Hebr-Book-414f88babad55bb44c9552d9b92504f7.woff2") format("woff2"), url("https://encore.scdn.co/fonts/CircularSp-Hebr-Book-d8209975eafc81a9499df8401a339ddd.woff") format("woff");
}

:root {
  --encore-title-font-stack: CircularSpTitle, CircularSpTitle-Tall, CircularSp-Arab, CircularSp-Hebr, CircularSp-Cyrl, CircularSp-Grek, CircularSp-Deva, var(--fallback-fonts, sans-serif);
  --encore-body-font-stack: CircularSp, CircularSp-Arab, CircularSp-Hebr, CircularSp-Cyrl, CircularSp-Grek, CircularSp-Deva, var(--fallback-fonts, sans-serif);
}

.encore-text-headline-large {
  font-size: var(--encore-text-size-larger-5);
  font-family: var(--encore-title-font-stack);
  font-weight: 900;
}

.encore-text-headline-medium {
  font-size: var(--encore-text-size-larger-4);
  font-family: var(--encore-title-font-stack);
  font-weight: 700;
}

.encore-text-title-large {
  font-size: var(--encore-text-size-larger-3);
  font-family: var(--encore-title-font-stack);
  font-weight: 700;
}

.encore-text-title-medium {
  font-size: var(--encore-text-size-larger-2);
  font-family: var(--encore-title-font-stack);
  font-weight: 700;
}

.encore-text-title-small {
  font-size: var(--encore-text-size-larger);
  font-family: var(--encore-title-font-stack);
  font-weight: 700;
}

.encore-text-body-medium {
  font-size: var(--encore-text-size-base);
  font-family: var(--encore-body-font-stack);
  font-weight: 400;
}

.encore-text-body-medium-bold {
  font-size: var(--encore-text-size-base);
  font-family: var(--encore-body-font-stack);
  font-weight: 700;
}

.encore-text-body-small {
  font-size: var(--encore-text-size-smaller);
  font-family: var(--encore-body-font-stack);
  font-weight: 400;
}

.encore-text-body-small-bold {
  font-size: var(--encore-text-size-smaller);
  font-family: var(--encore-body-font-stack);
  font-weight: 700;
}

.encore-text-marginal {
  font-size: var(--encore-text-size-smaller-2);
  font-family: var(--encore-body-font-stack);
  font-weight: 400;
}

.encore-text-marginal-bold {
  font-size: var(--encore-text-size-smaller-2);
  font-family: var(--encore-body-font-stack);
  font-weight: 700;
}

.encore-layout-themes, .encore-small-devices-theme {
  --encore-text-size-smaller-2: .6875rem;
  --encore-text-size-smaller: .8125rem;
  --encore-text-size-base: 1rem;
  --encore-text-size-larger: 1.25rem;
  --encore-text-size-larger-2: 1.5rem;
  --encore-text-size-larger-3: 2rem;
  --encore-text-size-larger-4: 2.5rem;
  --encore-text-size-larger-5: 3rem;
  --encore-border-width-hairline: 1px;
  --encore-border-width-thin: 2px;
  --encore-border-width-thick: 4px;
  --encore-border-width-thicker: 8px;
  --encore-border-width-focus: 3px;
  --encore-graphic-size-decorative-smaller-2: 12px;
  --encore-graphic-size-decorative-smaller: 16px;
  --encore-graphic-size-decorative-base: 24px;
  --encore-graphic-size-decorative-larger: 32px;
  --encore-graphic-size-decorative-larger-2: 40px;
  --encore-graphic-size-decorative-larger-3: 48px;
  --encore-graphic-size-decorative-larger-4: 64px;
  --encore-graphic-size-decorative-larger-5: 88px;
  --encore-graphic-size-informative-smaller-2: .75rem;
  --encore-graphic-size-informative-smaller: 1rem;
  --encore-graphic-size-informative-base: 1.5rem;
  --encore-graphic-size-informative-larger: 2rem;
  --encore-graphic-size-informative-larger-2: 2.5rem;
  --encore-graphic-size-informative-larger-3: 3rem;
  --encore-graphic-size-informative-larger-4: 4rem;
  --encore-graphic-size-informative-larger-5: 5.5rem;
  --encore-spacing-tighter-5: 2px;
  --encore-spacing-tighter-4: 4px;
  --encore-spacing-tighter-3: 6px;
  --encore-spacing-tighter-2: 8px;
  --encore-spacing-tighter: 12px;
  --encore-spacing-base: 16px;
  --encore-spacing-looser: 20px;
  --encore-spacing-looser-2: 24px;
  --encore-spacing-looser-3: 32px;
  --encore-spacing-looser-4: 40px;
  --encore-spacing-looser-5: 48px;
  --encore-spacing-looser-6: 64px;
  --encore-control-size-smaller: 32px;
  --encore-control-size-base: 48px;
  --encore-control-size-larger: 56px;
  --encore-layout-margin-tighter: 16px;
  --encore-layout-margin-base: 16px;
  --encore-layout-margin-looser: 24px;
  --encore-corner-radius-smaller: 2px;
  --encore-corner-radius-base: 4px;
  --encore-corner-radius-larger: 6px;
  --encore-corner-radius-larger-2: 8px;
  --encore-corner-radius-larger-3: 16px;
}

@media (min-width: 768px) {
  .encore-layout-themes {
    --encore-text-size-smaller-2: .75rem;
    --encore-text-size-smaller: .875rem;
    --encore-text-size-base: 1rem;
    --encore-text-size-larger: 1.5rem;
    --encore-text-size-larger-2: 2rem;
    --encore-text-size-larger-3: 3rem;
    --encore-text-size-larger-4: 4rem;
    --encore-text-size-larger-5: 6rem;
    --encore-border-width-hairline: 1px;
    --encore-border-width-thin: 2px;
    --encore-border-width-thick: 4px;
    --encore-border-width-thicker: 8px;
    --encore-border-width-focus: 3px;
    --encore-graphic-size-decorative-smaller-2: 12px;
    --encore-graphic-size-decorative-smaller: 16px;
    --encore-graphic-size-decorative-base: 24px;
    --encore-graphic-size-decorative-larger: 32px;
    --encore-graphic-size-decorative-larger-2: 40px;
    --encore-graphic-size-decorative-larger-3: 48px;
    --encore-graphic-size-decorative-larger-4: 64px;
    --encore-graphic-size-decorative-larger-5: 88px;
    --encore-graphic-size-informative-smaller-2: .75rem;
    --encore-graphic-size-informative-smaller: 1rem;
    --encore-graphic-size-informative-base: 1.5rem;
    --encore-graphic-size-informative-larger: 2rem;
    --encore-graphic-size-informative-larger-2: 2.5rem;
    --encore-graphic-size-informative-larger-3: 3rem;
    --encore-graphic-size-informative-larger-4: 4rem;
    --encore-graphic-size-informative-larger-5: 5.5rem;
    --encore-spacing-tighter-5: 2px;
    --encore-spacing-tighter-4: 4px;
    --encore-spacing-tighter-3: 6px;
    --encore-spacing-tighter-2: 8px;
    --encore-spacing-tighter: 12px;
    --encore-spacing-base: 16px;
    --encore-spacing-looser: 24px;
    --encore-spacing-looser-2: 32px;
    --encore-spacing-looser-3: 48px;
    --encore-spacing-looser-4: 64px;
    --encore-spacing-looser-5: 96px;
    --encore-spacing-looser-6: 128px;
    --encore-control-size-smaller: 32px;
    --encore-control-size-base: 48px;
    --encore-control-size-larger: 56px;
    --encore-layout-margin-tighter: 24px;
    --encore-layout-margin-base: 32px;
    --encore-layout-margin-looser: 64px;
    --encore-corner-radius-smaller: 2px;
    --encore-corner-radius-base: 4px;
    --encore-corner-radius-larger: 6px;
    --encore-corner-radius-larger-2: 8px;
    --encore-corner-radius-larger-3: 16px;
  }
}

.encore-medium-devices-theme {
  --encore-text-size-smaller-2: .75rem;
  --encore-text-size-smaller: .875rem;
  --encore-text-size-base: 1rem;
  --encore-text-size-larger: 1.5rem;
  --encore-text-size-larger-2: 2rem;
  --encore-text-size-larger-3: 3rem;
  --encore-text-size-larger-4: 4rem;
  --encore-text-size-larger-5: 6rem;
  --encore-border-width-hairline: 1px;
  --encore-border-width-thin: 2px;
  --encore-border-width-thick: 4px;
  --encore-border-width-thicker: 8px;
  --encore-border-width-focus: 3px;
  --encore-graphic-size-decorative-smaller-2: 12px;
  --encore-graphic-size-decorative-smaller: 16px;
  --encore-graphic-size-decorative-base: 24px;
  --encore-graphic-size-decorative-larger: 32px;
  --encore-graphic-size-decorative-larger-2: 40px;
  --encore-graphic-size-decorative-larger-3: 48px;
  --encore-graphic-size-decorative-larger-4: 64px;
  --encore-graphic-size-decorative-larger-5: 88px;
  --encore-graphic-size-informative-smaller-2: .75rem;
  --encore-graphic-size-informative-smaller: 1rem;
  --encore-graphic-size-informative-base: 1.5rem;
  --encore-graphic-size-informative-larger: 2rem;
  --encore-graphic-size-informative-larger-2: 2.5rem;
  --encore-graphic-size-informative-larger-3: 3rem;
  --encore-graphic-size-informative-larger-4: 4rem;
  --encore-graphic-size-informative-larger-5: 5.5rem;
  --encore-spacing-tighter-5: 2px;
  --encore-spacing-tighter-4: 4px;
  --encore-spacing-tighter-3: 6px;
  --encore-spacing-tighter-2: 8px;
  --encore-spacing-tighter: 12px;
  --encore-spacing-base: 16px;
  --encore-spacing-looser: 24px;
  --encore-spacing-looser-2: 32px;
  --encore-spacing-looser-3: 48px;
  --encore-spacing-looser-4: 64px;
  --encore-spacing-looser-5: 96px;
  --encore-spacing-looser-6: 128px;
  --encore-control-size-smaller: 32px;
  --encore-control-size-base: 48px;
  --encore-control-size-larger: 56px;
  --encore-layout-margin-tighter: 24px;
  --encore-layout-margin-base: 32px;
  --encore-layout-margin-looser: 64px;
  --encore-corner-radius-smaller: 2px;
  --encore-corner-radius-base: 4px;
  --encore-corner-radius-larger: 6px;
  --encore-corner-radius-larger-2: 8px;
  --encore-corner-radius-larger-3: 16px;
}

/*# sourceMappingURL=index.da4ccaa0.css.map */
