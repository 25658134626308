@font-face {
  font-family: CircularSp-Deva;
  font-weight: 900;
  font-display: swap;
  unicode-range: U+0000, U+000D, U+0020, U+00A0, U+00A4, U+00B6-00B7,
    U+0901-097F, U+200C-200E, U+FFFF;
  src: url('https://encore.scdn.co/fonts/CircularSp-Deva-Black-a0e7d7aa929b3aa7d57e5773563de713.woff2')
      format('woff2'),
    url('https://encore.scdn.co/fonts/CircularSp-Deva-Black-bbe191a7814d06a3eecc724265dae2d0.woff')
      format('woff');
}
@font-face {
  font-family: CircularSp-Deva;
  font-weight: 700;
  font-display: swap;
  unicode-range: U+0000, U+000D, U+0020, U+00A0, U+00A4, U+00B6-00B7,
    U+0901-097F, U+200C-200E, U+FFFF;
  src: url('https://encore.scdn.co/fonts/CircularSp-Deva-Bold-4f56f02173de0561d370b2565f87aa1c.woff2')
      format('woff2'),
    url('https://encore.scdn.co/fonts/CircularSp-Deva-Bold-a212adfa8f476d3544664463d5759178.woff')
      format('woff');
}
@font-face {
  font-family: CircularSp-Deva;
  font-weight: 400;
  font-display: swap;
  unicode-range: U+0000, U+000D, U+0020, U+00A0, U+00A4, U+00B6-00B7,
    U+0901-097F, U+200C-200E, U+FFFF;
  src: url('https://encore.scdn.co/fonts/CircularSp-Deva-Book-a2baf2e8b66dbbd3f7ff84d69d733ca0.woff2')
      format('woff2'),
    url('https://encore.scdn.co/fonts/CircularSp-Deva-Book-159c0e02bc9dc7dd0830fb569faf1069.woff')
      format('woff');
}
@font-face {
  font-family: CircularSp-Grek;
  font-weight: 900;
  font-display: swap;
  unicode-range: U+0000, U+000D, U+0020, U+00A0, U+00A4, U+00B6-00B7, U+0375,
    U+037A, U+0384, U+0386, U+0388-038A, U+038C, U+038E-03A1, U+03A3-03CF,
    U+03D7, U+2126, U+FFFF;
  src: url('https://encore.scdn.co/fonts/CircularSp-Grek-Black-b45055a950eac7560342cb7fa07559ad.woff2')
      format('woff2'),
    url('https://encore.scdn.co/fonts/CircularSp-Grek-Black-49883536c1a3bfc688235ce40572731d.woff')
      format('woff');
}
@font-face {
  font-family: CircularSp-Grek;
  font-weight: 700;
  font-display: swap;
  unicode-range: U+0000, U+000D, U+0020, U+00A0, U+00A4, U+00B6-00B7, U+0375,
    U+037A, U+0384, U+0386, U+0388-038A, U+038C, U+038E-03A1, U+03A3-03CF,
    U+03D7, U+2126, U+FFFF;
  src: url('https://encore.scdn.co/fonts/CircularSp-Grek-Bold-fafb1410c85fe7e6e81c68e609d4b823.woff2')
      format('woff2'),
    url('https://encore.scdn.co/fonts/CircularSp-Grek-Bold-53bb923248ba22cf5554bbe5f434c5c8.woff')
      format('woff');
}
@font-face {
  font-family: CircularSp-Grek;
  font-weight: 400;
  font-display: swap;
  unicode-range: U+0000, U+000D, U+0020, U+00A0, U+00A4, U+00B6-00B7, U+0375,
    U+037A, U+0384, U+0386, U+0388-038A, U+038C, U+038E-03A1, U+03A3-03CF,
    U+03D7, U+2126, U+FFFF;
  src: url('https://encore.scdn.co/fonts/CircularSp-Grek-Book-5d5a3a10aa26b63a2575ce872ad43f07.woff2')
      format('woff2'),
    url('https://encore.scdn.co/fonts/CircularSp-Grek-Book-c9de2c0741586c1ab7b6e95541fc7807.woff')
      format('woff');
}
@font-face {
  font-family: CircularSpTitle;
  font-weight: 900;
  font-display: swap;
  unicode-range: U+0000, U+0020-007E, U+00A0-00BF, U+00C6-00C7, U+00D0, U+00D7,
    U+00DE-00E4, U+00E6-00EF, U+00F1-00FF, U+0101, U+0103-0105, U+0107, U+0109,
    U+010B, U+010D, U+010F-0111, U+0113, U+0115, U+0117-0119, U+011B, U+011D,
    U+011F, U+0121, U+0126-0127, U+0129, U+012B, U+012D-012F, U+0131-0133,
    U+0135, U+0138, U+013E-0142, U+0144, U+0148, U+014A-014B, U+014D, U+014F,
    U+0151-0153, U+0155, U+0159, U+015B, U+015D-015F, U+0161-0163, U+0166-0167,
    U+0169, U+016B, U+016D, U+0171-0173, U+0175, U+0177, U+017A, U+017C, U+017E,
    U+018F, U+0192, U+01A1, U+01B0, U+01B5-01B6, U+01C5-01C6, U+01F1-01F3,
    U+01FD, U+01FF, U+0237, U+0259, U+02C6-02C7, U+02C9, U+02D8-02D9,
    U+02DB-02DD, U+0394, U+03A9, U+03BC, U+03C0, U+1E81, U+1E83, U+1E85, U+1E8B,
    U+1EA0-1EA1, U+1EAD, U+1EB7-1EB9, U+1EBD, U+1EC7, U+1ECA-1ECD, U+1ED9,
    U+1EDB, U+1EDD, U+1EE3-1EE5, U+1EE9, U+1EEB, U+1EF1, U+1EF3-1EF5, U+1EF9,
    U+1FD6, U+2007-2008, U+200B, U+2010-2011, U+2013-2014, U+2018-201A,
    U+201C-201E, U+2020-2022, U+2026, U+2030, U+2032-2033, U+2039-203A, U+2042,
    U+2044, U+2051, U+2070, U+2074-2079, U+2080-2089, U+20AB-20AC, U+2113,
    U+2117, U+2122, U+2126, U+2160-2169, U+216C-216F, U+2190-2193, U+2196-2199,
    U+21A9, U+21B0-21B5, U+21C6, U+2202, U+2206, U+220F, U+2211-2212, U+2215,
    U+2219, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+22C5, U+24C5,
    U+25A0-25A1, U+25AF, U+25B2-25B3, U+25CA-25CB, U+25CF, U+262E, U+2713,
    U+2715, U+2780-2788, U+E000, U+E002, U+FB00-FB04, U+FEFF, U+FF0C, U+FF0E,
    U+FF1A-FF1B, U+FFFF;
  src: url('https://encore.scdn.co/fonts/CircularSpTitle-Black-3f9afb402080d53345ca1850226ca724.woff2')
      format('woff2'),
    url('https://encore.scdn.co/fonts/CircularSpTitle-Black-b063ad0a42cbaf519c7ccba8b3e1caeb.woff')
      format('woff');
}
@font-face {
  font-family: CircularSpTitle;
  font-weight: 700;
  font-display: swap;
  unicode-range: U+0000, U+0020-007E, U+00A0-00BF, U+00C6-00C7, U+00D0, U+00D7,
    U+00DE-00E4, U+00E6-00EF, U+00F1-00FF, U+0101, U+0103-0105, U+0107, U+0109,
    U+010B, U+010D, U+010F-0111, U+0113, U+0115, U+0117-0119, U+011B, U+011D,
    U+011F, U+0121, U+0126-0127, U+0129, U+012B, U+012D-012F, U+0131-0133,
    U+0135, U+0138, U+013E-0142, U+0144, U+0148, U+014A-014B, U+014D, U+014F,
    U+0151-0153, U+0155, U+0159, U+015B, U+015D-015F, U+0161-0163, U+0166-0167,
    U+0169, U+016B, U+016D, U+0171-0173, U+0175, U+0177, U+017A, U+017C, U+017E,
    U+018F, U+0192, U+01A1, U+01B0, U+01B5-01B6, U+01C5-01C6, U+01F1-01F3,
    U+01FD, U+01FF, U+0237, U+0259, U+02C6-02C7, U+02C9, U+02D8-02D9,
    U+02DB-02DD, U+0394, U+03A9, U+03BC, U+03C0, U+1E81, U+1E83, U+1E85, U+1E8B,
    U+1EA0-1EA1, U+1EAD, U+1EB7-1EB9, U+1EBD, U+1EC7, U+1ECA-1ECD, U+1ED9,
    U+1EDB, U+1EDD, U+1EE3-1EE5, U+1EE9, U+1EEB, U+1EF1, U+1EF3-1EF5, U+1EF9,
    U+1FD6, U+2007-2008, U+200B, U+2010-2011, U+2013-2014, U+2018-201A,
    U+201C-201E, U+2020-2022, U+2026, U+2030, U+2032-2033, U+2039-203A, U+2042,
    U+2044, U+2051, U+2070, U+2074-2079, U+2080-2089, U+20AB-20AC, U+2113,
    U+2117, U+2122, U+2126, U+2160-2169, U+216C-216F, U+2190-2193, U+2196-2199,
    U+21A9, U+21B0-21B5, U+21C6, U+2202, U+2206, U+220F, U+2211-2212, U+2215,
    U+2219, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+22C5, U+24C5,
    U+25A0-25A1, U+25AF, U+25B2-25B3, U+25CA-25CB, U+25CF, U+262E, U+2713,
    U+2715, U+2780-2788, U+E000, U+E002, U+FB00-FB04, U+FEFF, U+FF0C, U+FF0E,
    U+FF1A-FF1B, U+FFFF;
  src: url('https://encore.scdn.co/fonts/CircularSpTitle-Bold-2fbf72b606d7f0b0f771ea4956a8b4d6.woff2')
      format('woff2'),
    url('https://encore.scdn.co/fonts/CircularSpTitle-Bold-7cd3208b73fd0850bca5d8f7821439cd.woff')
      format('woff');
}
@font-face {
  font-family: CircularSpTitle-Tall;
  font-weight: 900;
  font-display: swap;
  unicode-range: U+0000, U+0020, U+00A0, U+00A4, U+00B6-00B7, U+00C0-00C5,
    U+00C8-00CF, U+00D1-00D6, U+00D8-00DD, U+00E5, U+00F0, U+0100, U+0102,
    U+0106, U+0108, U+010A, U+010C, U+010E, U+0112, U+0114, U+0116, U+011A,
    U+011C, U+011E, U+0120, U+0122-0125, U+0128, U+012A, U+012C, U+0130, U+0134,
    U+0136-0137, U+0139-013D, U+0143, U+0145-0147, U+0149, U+014C, U+014E,
    U+0150, U+0154, U+0156-0158, U+015A, U+015C, U+0160, U+0164-0165, U+0168,
    U+016A, U+016C, U+016E-0170, U+0174, U+0176, U+0178-0179, U+017B, U+017D,
    U+01A0, U+01AF, U+01C4, U+01FA-01FC, U+01FE, U+0218-021B, U+02BB-02BC,
    U+02DA, U+0300-0301, U+0303, U+0309, U+0323, U+1E80, U+1E82, U+1E84, U+1E8A,
    U+1EA2-1EAC, U+1EAE-1EB6, U+1EBA-1EBC, U+1EBE-1EC6, U+1EC8-1EC9, U+1ECE-1ED8,
    U+1EDA, U+1EDC, U+1EDE-1EE2, U+1EE6-1EE8, U+1EEA, U+1EEC-1EF0, U+1EF2,
    U+1EF6-1EF8, U+221A, U+F6C3, U+FFFF;
  src: url('https://encore.scdn.co/fonts/CircularSpTitle-Tall-Black-dd3ec8c5997b5c7135fb3716866ded28.woff2')
      format('woff2'),
    url('https://encore.scdn.co/fonts/CircularSpTitle-Tall-Black-35a438fa59bc07bdf08133e438d72409.woff')
      format('woff');
}
@font-face {
  font-family: CircularSpTitle-Tall;
  font-weight: 700;
  font-display: swap;
  unicode-range: U+0000, U+0020, U+00A0, U+00A4, U+00B6-00B7, U+00C0-00C5,
    U+00C8-00CF, U+00D1-00D6, U+00D8-00DD, U+00E5, U+00F0, U+0100, U+0102,
    U+0106, U+0108, U+010A, U+010C, U+010E, U+0112, U+0114, U+0116, U+011A,
    U+011C, U+011E, U+0120, U+0122-0125, U+0128, U+012A, U+012C, U+0130, U+0134,
    U+0136-0137, U+0139-013D, U+0143, U+0145-0147, U+0149, U+014C, U+014E,
    U+0150, U+0154, U+0156-0158, U+015A, U+015C, U+0160, U+0164-0165, U+0168,
    U+016A, U+016C, U+016E-0170, U+0174, U+0176, U+0178-0179, U+017B, U+017D,
    U+01A0, U+01AF, U+01C4, U+01FA-01FC, U+01FE, U+0218-021B, U+02BB-02BC,
    U+02DA, U+0300-0301, U+0303, U+0309, U+0323, U+1E80, U+1E82, U+1E84, U+1E8A,
    U+1EA2-1EAC, U+1EAE-1EB6, U+1EBA-1EBC, U+1EBE-1EC6, U+1EC8-1EC9, U+1ECE-1ED8,
    U+1EDA, U+1EDC, U+1EDE-1EE2, U+1EE6-1EE8, U+1EEA, U+1EEC-1EF0, U+1EF2,
    U+1EF6-1EF8, U+221A, U+F6C3, U+FFFF;
  src: url('https://encore.scdn.co/fonts/CircularSpTitle-Tall-Bold-69995af8079e90f1d15130df13ad0c60.woff2')
      format('woff2'),
    url('https://encore.scdn.co/fonts/CircularSpTitle-Tall-Bold-24c7e518b6e633e498760fab4aa88218.woff')
      format('woff');
}
@font-face {
  font-family: CircularSp;
  font-weight: 700;
  font-display: swap;
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-017E, U+018F, U+0192,
    U+01A0-01A1, U+01AF-01B0, U+01B5-01B6, U+01C4-01C6, U+01F1-01F3, U+01FA-01FF,
    U+0218-021B, U+0237, U+0259, U+02BB-02BC, U+02C6-02C7, U+02C9, U+02D8-02DD,
    U+0300-0301, U+0303, U+0309, U+0323, U+0394, U+03A9, U+03BC, U+03C0,
    U+1E80-1E85, U+1E8A-1E8B, U+1EA0-1EF9, U+1FD6, U+2007-2008, U+200B,
    U+2010-2011, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026,
    U+2030, U+2032-2033, U+2039-203A, U+2042, U+2044, U+2051, U+2070,
    U+2074-2079, U+2080-2089, U+20AB-20AC, U+2113, U+2117, U+2122, U+2126,
    U+2160-2169, U+216C-216F, U+2190-2193, U+2196-2199, U+21A9, U+21B0-21B5,
    U+21C6, U+2202, U+2206, U+220F, U+2211-2212, U+2215, U+2219-221A, U+221E,
    U+222B, U+2248, U+2260, U+2264-2265, U+22C5, U+24C5, U+25A0-25A1, U+25AF,
    U+25B2-25B3, U+25CA-25CB, U+25CF, U+262E, U+2713, U+2715, U+2780-2788,
    U+E000, U+E002, U+F6C3, U+FB00-FB04, U+FEFF, U+FF0C, U+FF0E, U+FF1A-FF1B,
    U+FFFF;
  src: url('https://encore.scdn.co/fonts/CircularSp-Bold-fe1cfc14b7498b187c78fa72fb72d148.woff2')
      format('woff2'),
    url('https://encore.scdn.co/fonts/CircularSp-Bold-79ebb2bdea919cebedea4884d08c499e.woff')
      format('woff');
}
@font-face {
  font-family: CircularSp;
  font-weight: 400;
  font-display: swap;
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-017E, U+018F, U+0192,
    U+01A0-01A1, U+01AF-01B0, U+01B5-01B6, U+01C4-01C6, U+01F1-01F3, U+01FA-01FF,
    U+0218-021B, U+0237, U+0259, U+02BB-02BC, U+02C6-02C7, U+02C9, U+02D8-02DD,
    U+0300-0301, U+0303, U+0309, U+0323, U+0394, U+03A9, U+03BC, U+03C0,
    U+1E80-1E85, U+1E8A-1E8B, U+1EA0-1EF9, U+1FD6, U+2007-2008, U+200B,
    U+2010-2011, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026,
    U+2030, U+2032-2033, U+2039-203A, U+2042, U+2044, U+2051, U+2070,
    U+2074-2079, U+2080-2089, U+20AB-20AC, U+2113, U+2117, U+2122, U+2126,
    U+2160-2169, U+216C-216F, U+2190-2193, U+2196-2199, U+21A9, U+21B0-21B5,
    U+21C6, U+2202, U+2206, U+220F, U+2211-2212, U+2215, U+2219-221A, U+221E,
    U+222B, U+2248, U+2260, U+2264-2265, U+22C5, U+24C5, U+25A0-25A1, U+25AF,
    U+25B2-25B3, U+25CA-25CB, U+25CF, U+262E, U+2713, U+2715, U+2780-2788,
    U+E000, U+E002, U+F6C3, U+FB00-FB04, U+FEFF, U+FF0C, U+FF0E, U+FF1A-FF1B,
    U+FFFF;
  src: url('https://encore.scdn.co/fonts/CircularSp-Book-4eaffdf96f4c6f984686e93d5d9cb325.woff2')
      format('woff2'),
    url('https://encore.scdn.co/fonts/CircularSp-Book-4f217aebee53ffc72f4444f79041ffa5.woff')
      format('woff');
}
@font-face {
  font-family: CircularSp-Arab;
  font-weight: 900;
  font-display: swap;
  unicode-range: U+0000, U+000D, U+0020, U+00A0, U+00A4, U+00B6-00B7,
    U+01C4-01C6, U+01F1-01F3, U+02BB-02BC, U+0600-0603, U+060B-060C, U+0610,
    U+061B, U+061F, U+0621-063A, U+0640-0659, U+0660-0671, U+0679-0681,
    U+0683-068A, U+068C-068D, U+068F, U+0691, U+0693, U+0696, U+0698-069A,
    U+06A1, U+06A4, U+06A6, U+06A9-06AB, U+06AF, U+06B1, U+06B3, U+06BA-06BC,
    U+06BE, U+06C0-06C3, U+06CC-06CD, U+06D0, U+06D2-06D5, U+06F0-06F9,
    U+06FD-06FE, U+2002-2003, U+2009, U+200C-200F, U+25CC, U+FB50-FB85,
    U+FB88-FBBB, U+FBBD-FBC1, U+FBE4-FBE9, U+FBFC-FBFF, U+FC48, U+FC5E-FC63,
    U+FD3E-FD3F, U+FDF2, U+FDFC, U+FE80-FEFC, U+FFFF;
  src: url('https://encore.scdn.co/fonts/CircularSp-Arab-Black-45efa22567f23866c34554b4a118b7eb.woff2')
      format('woff2'),
    url('https://encore.scdn.co/fonts/CircularSp-Arab-Black-85253c80e7df46d62c4d5420e1cc5f4c.woff')
      format('woff');
}
@font-face {
  font-family: CircularSp-Arab;
  font-weight: 700;
  font-display: swap;
  unicode-range: U+0000, U+000D, U+0020, U+00A0, U+00A4, U+00B6-00B7,
    U+01C4-01C6, U+01F1-01F3, U+02BB-02BC, U+0600-0603, U+060B-060C, U+0610,
    U+061B, U+061F, U+0621-063A, U+0640-0659, U+0660-0671, U+0679-0681,
    U+0683-068A, U+068C-068D, U+068F, U+0691, U+0693, U+0696, U+0698-069A,
    U+06A1, U+06A4, U+06A6, U+06A9-06AB, U+06AF, U+06B1, U+06B3, U+06BA-06BC,
    U+06BE, U+06C0-06C3, U+06CC-06CD, U+06D0, U+06D2-06D5, U+06F0-06F9,
    U+06FD-06FE, U+2002-2003, U+2009, U+200C-200F, U+25CC, U+FB50-FB85,
    U+FB88-FBBB, U+FBBD-FBC1, U+FBE4-FBE9, U+FBFC-FBFF, U+FC48, U+FC5E-FC63,
    U+FD3E-FD3F, U+FDF2, U+FDFC, U+FE80-FEFC, U+FFFF;
  src: url('https://encore.scdn.co/fonts/CircularSp-Arab-Bold-55131a638ddbb83219f034dab0c4f2ef.woff2')
      format('woff2'),
    url('https://encore.scdn.co/fonts/CircularSp-Arab-Bold-f6f9a0b56c26078440d40b145f48ab5a.woff')
      format('woff');
}
@font-face {
  font-family: CircularSp-Arab;
  font-weight: 400;
  font-display: swap;
  unicode-range: U+0000, U+000D, U+0020, U+00A0, U+00A4, U+00B6-00B7,
    U+01C4-01C6, U+01F1-01F3, U+02BB-02BC, U+0600-0603, U+060B-060C, U+0610,
    U+061B, U+061F, U+0621-063A, U+0640-0659, U+0660-0671, U+0679-0681,
    U+0683-068A, U+068C-068D, U+068F, U+0691, U+0693, U+0696, U+0698-069A,
    U+06A1, U+06A4, U+06A6, U+06A9-06AB, U+06AF, U+06B1, U+06B3, U+06BA-06BC,
    U+06BE, U+06C0-06C3, U+06CC-06CD, U+06D0, U+06D2-06D5, U+06F0-06F9,
    U+06FD-06FE, U+2002-2003, U+2009, U+200C-200F, U+25CC, U+FB50-FB85,
    U+FB88-FBBB, U+FBBD-FBC1, U+FBE4-FBE9, U+FBFC-FBFF, U+FC48, U+FC5E-FC63,
    U+FD3E-FD3F, U+FDF2, U+FDFC, U+FE80-FEFC, U+FFFF;
  src: url('https://encore.scdn.co/fonts/CircularSp-Arab-Book-2cf93eecef4be930c68c114d308d76c2.woff2')
      format('woff2'),
    url('https://encore.scdn.co/fonts/CircularSp-Arab-Book-15ab1b42ca6ef5894519756031ee3ed5.woff')
      format('woff');
}
@font-face {
  font-family: CircularSp-Cyrl;
  font-weight: 900;
  font-display: swap;
  unicode-range: U+0000, U+000D, U+0020, U+00A0, U+00A4, U+00B6-00B7,
    U+0400-045F, U+0462-0463, U+0472-0475, U+0490-0493, U+049A-049B, U+04A2-04A3,
    U+04AE-04B3, U+04BA-04BB, U+04D8-04D9, U+04E8-04E9, U+FFFF;
  src: url('https://encore.scdn.co/fonts/CircularSp-Cyrl-Black-1ce6672693e92d2cb1d04af1690e1f9c.woff2')
      format('woff2'),
    url('https://encore.scdn.co/fonts/CircularSp-Cyrl-Black-b4305d9bf82554f631d2636c3ef90c54.woff')
      format('woff');
}
@font-face {
  font-family: CircularSp-Cyrl;
  font-weight: 700;
  font-display: swap;
  unicode-range: U+0000, U+000D, U+0020, U+00A0, U+00A4, U+00B6-00B7,
    U+0400-045F, U+0462-0463, U+0472-0475, U+0490-0493, U+049A-049B, U+04A2-04A3,
    U+04AE-04B3, U+04BA-04BB, U+04D8-04D9, U+04E8-04E9, U+FFFF;
  src: url('https://encore.scdn.co/fonts/CircularSp-Cyrl-Bold-180db050d109afeef4331b1ee551c349.woff2')
      format('woff2'),
    url('https://encore.scdn.co/fonts/CircularSp-Cyrl-Bold-7e54bccaf45728c472079785d5cd4519.woff')
      format('woff');
}
@font-face {
  font-family: CircularSp-Cyrl;
  font-weight: 400;
  font-display: swap;
  unicode-range: U+0000, U+000D, U+0020, U+00A0, U+00A4, U+00B6-00B7,
    U+0400-045F, U+0462-0463, U+0472-0475, U+0490-0493, U+049A-049B, U+04A2-04A3,
    U+04AE-04B3, U+04BA-04BB, U+04D8-04D9, U+04E8-04E9, U+FFFF;
  src: url('https://encore.scdn.co/fonts/CircularSp-Cyrl-Book-cc791a2e69e25c0440792bd855600ca7.woff2')
      format('woff2'),
    url('https://encore.scdn.co/fonts/CircularSp-Cyrl-Book-6f078f781ee313e298ad8997fd1ffe3d.woff')
      format('woff');
}
@font-face {
  font-family: CircularSp-Hebr;
  font-weight: 900;
  font-display: swap;
  unicode-range: U+0000, U+000D, U+0020, U+0030-0039, U+00A0, U+00A4,
    U+00B6-00B7, U+01C4-01C6, U+01F1-01F3, U+02BB-02BC, U+05B0-05C4, U+05D0-05EA,
    U+05F0-05F4, U+200E-200F, U+20AA, U+FB2A-FB36, U+FB38-FB3C, U+FB3E,
    U+FB40-FB41, U+FB43-FB44, U+FB46-FB4F, U+FFFF;
  src: url('https://encore.scdn.co/fonts/CircularSp-Hebr-Black-29a8af6f5782cdf3009a5ac966ee4fa6.woff2')
      format('woff2'),
    url('https://encore.scdn.co/fonts/CircularSp-Hebr-Black-f52613a9d541248805af1d0bb33102f8.woff')
      format('woff');
}
@font-face {
  font-family: CircularSp-Hebr;
  font-weight: 700;
  font-display: swap;
  unicode-range: U+0000, U+000D, U+0020, U+0030-0039, U+00A0, U+00A4,
    U+00B6-00B7, U+01C4-01C6, U+01F1-01F3, U+02BB-02BC, U+05B0-05C4, U+05D0-05EA,
    U+05F0-05F4, U+200E-200F, U+20AA, U+FB2A-FB36, U+FB38-FB3C, U+FB3E,
    U+FB40-FB41, U+FB43-FB44, U+FB46-FB4F, U+FFFF;
  src: url('https://encore.scdn.co/fonts/CircularSp-Hebr-Bold-9b609e9268a3f9d73732dc3cc375821e.woff2')
      format('woff2'),
    url('https://encore.scdn.co/fonts/CircularSp-Hebr-Bold-caa03e4cb8690e726ef1625c7d91a7a5.woff')
      format('woff');
}
@font-face {
  font-family: CircularSp-Hebr;
  font-weight: 400;
  font-display: swap;
  unicode-range: U+0000, U+000D, U+0020, U+0030-0039, U+00A0, U+00A4,
    U+00B6-00B7, U+01C4-01C6, U+01F1-01F3, U+02BB-02BC, U+05B0-05C4, U+05D0-05EA,
    U+05F0-05F4, U+200E-200F, U+20AA, U+FB2A-FB36, U+FB38-FB3C, U+FB3E,
    U+FB40-FB41, U+FB43-FB44, U+FB46-FB4F, U+FFFF;
  src: url('https://encore.scdn.co/fonts/CircularSp-Hebr-Book-414f88babad55bb44c9552d9b92504f7.woff2')
      format('woff2'),
    url('https://encore.scdn.co/fonts/CircularSp-Hebr-Book-d8209975eafc81a9499df8401a339ddd.woff')
      format('woff');
}
