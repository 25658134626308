html {
  /* Base */
  --white: #fff;
  --black: #000;
  /* Gray */
  --gray-1: #f6f6f6; /* rebrand */
  --gray-2: #f2f3f5;
  --gray-3: #e1e4e8;
  --gray-4: #dadde1;
  --gray-5: #ccd0d5;
  --gray-6: #bec3c9;
  --gray-7: #8d949e;
  --gray-8: #6a6a6a; /* rebrand */
  --gray-9: #444950;
  --gray-10: #1c1e21;
  /* Brand Colors */
  --spotify-yellow: #CDF564;
  --green-podsights: #CDF564;

  --blue-dark: #460e3c;
  --blue: #a1258a;
  --blue-80: #b551a1;
  --blue-60: #c77cb9;
  --blue-40: #d9a8d0;
  --blue-20: #ecd3e8;
  --blue-10: #f6e9f3;
  --green-dark: #00975d;
  --green: #1ED760;
  --green-80: #45e57e;
  --green-60: #73ec9e;
  --green-40: #a2f2bf;
  --green-20: #d0f9df;
  --green-10: #e8fcef;
  --highlighter-dark: #847504;
  --highlighter: #e7fe02;
  --highlighter-80: #e7fe02;
  --highlighter-60: #f1fe67;
  --highlighter-40: #f5ff9a;
  --highlighter-20: #faffcc;
  --highlighter-10: #fdffe6;
  --red-orange-dark: #e12a16;
  --red-orange: #ff4632;
  --red-orange-80: #ff6b5b;
  --red-orange-60: #ff9084;
  --red-orange-40: #ffb5ad;
  --red-orange-20: #ffdad6;
  --red-orange-10: #ffecea;
  --pink: #ff94d5;
  --pink-80: #ffa9dd;
  --pink-60: #ffbfe6;
  --pink-40: #ffd4ee;
  --pink-20: #ffeaf7;
  --pink-10: #fff4fb;
  /* Base/Action Colors */
  --default: #BDBDBD;
  --primary: var(--blue);
  --secondary: var(--spotify-yellow);
  --error: var(--red-orange);
  --info: var(--blue);
  --success: #16D0A6;
  --warning: #FFA42B;
  /* Text on Base/Action colors */
  --text-on-primary: var(--white);
  --text-on-secondary: var(--gray-10);
  --text-on-error: var(--white);
  --text-on-info: var(--white);
  --text-on-success: var(--white);
  --text-on-warning: var(--white);
  /* Text Base/Action Colors */
  --text-primary: var(--blue-dark);
  --text-secondary: var(--green-dark);
  --text-error: var(--red-orange-dark);
  --text-success: var(--green-dark);
  --text-info: var(--blue-dark);
  --text-warning: var(--highlighter-dark);
  /* Font  */
  --font-family-base: CircularSp, Helvetica, Arial, sans-serif;
  --font-family-title: CircularSpTitle, Helvetica, Arial, sans-serif;
  /* Font Size */
  --font-size-h1: 2.5rem;
  --font-size-h2: 1.75rem;
  --font-size-h3: 1.375rem;
  --font-size-h4: 1.125rem;
  --font-size-h5: 1.0625rem;
  --font-size-body-1: 1rem;
  --font-size-body-2: 0.875rem;
  --font-size-body-3: 0.8125rem;
  --font-size-body-4: 0.6875rem;
  /* Line Height */
  --line-height-xs: 1.3;
  --line-height-sm: 1.4;
  --line-height-md: 1.5;
  --line-height-lg: 1.6;
  --line-height-xl: 1.75;
  /* Font Weight */
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-weight-extrabold: 900;
  /* Spacing (factor of 4) */
  --spacing-1: 0.25rem;
  --spacing-2: 0.5rem;
  --spacing-3: 0.75rem;
  --spacing-4: 1rem;
  --spacing-5: 1.25rem;
  --spacing-6: 1.5rem;
  --spacing-7: 1.75rem;
  --spacing-8: 2rem;
  --spacing-9: 2.25rem;
  --spacing-10: 2.5rem;
  --spacing-11: 2.75rem;
  --spacing-12: 3rem;
  --spacing-13: 3.25rem;
  --spacing-14: 3.5rem;
  --spacing-15: 3.75rem;
  /* Radius */
  --radius-1: 0.75rem;
  --radius-2: 1.25rem;
  --radius-3: 2rem;
  --radius-4: 3rem;
  /* UI Stack */
  --stack-padding-condensed: var(--size-2);
  --stack-padding-normal: var(--size-4);
  --stack-padding-spacious: var(--size-6);
  --stack-gap-condensed: var(--size-2);
  --stack-gap-normal: var(--size-4);
  --stack-gap-spacious: var(--size-6);
  /* Components */
  --app-left-nav-width: 13rem;
  --input-height-sm: 2.125rem;
  --input-height-md: 2.625rem;
  --input-border: 1px solid var(--border-muted);
  --input-border-error: 1px solid var(--border-muted);
  --input-border-radius-sm: 3px;
  --input-border-radius-md: 4px;

  /* App */
  --advisor-affinity-color: #953086;
  --advisor-affinity-bg: transparent;
  --advisor-affinity-megaphone-color: #6347ff;
  --advisor-affinity-megaphone-bg: #dfd7fe;
  --advisor-reach-color: #953086;
  --advisor-reach-bg: transparent;
}
